import axios from "./api";

class UserService {
  getUsers(status, skip, limit, sort, order, search, role){
    var params = '?';
    if(status && status !== null && status !== '')
      params+='status='+status+'&'
    if(skip && skip !== null && skip !== '')
      params+='skip='+skip+'&'
    if(limit && limit !== null && limit !== '')
      params+='limit='+limit+'&'
    if(sort && sort !== null && sort !== '')
      params+='sort='+sort+'&'
    if(order && order !== null && order !== '')
      params+='order='+order+'&'
    if(search && search !== null && search !== '')
      params+='search='+search+'&'
    if(role && role !== null && role !== '')
      params+='role='+role+'&'
    return axios.get('/users'+params);
  }
  getUser(id) {
    return axios.get('/users/'+id);
  }

  getRoles(id) {
    return axios.get('/roles');
  }

  updateUser(id, json){
    return axios.put('/users/'+id, json);
  }

  createUser(json){
    return axios.post('/users', json);
  }
}

export default new UserService();
