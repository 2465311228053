<template>
    <Modal v-if="isVisibility" @toggle-modal="toggleModal">      
        <template v-slot:header--modal>
            <h2>{{$t('MODAL_TITLE_CAMP')}}</h2>
            <p>{{$t('MODAL_TITLE_DESC')}}</p>
        </template>

        <template v-slot:container--modal>
            <form>
                <div class="card__modal grid-form-100">
                    <div class="btn--invoice grid-form-100">
                        <a href="#" @click="openNewTab()" class="button view--invoice">
                            <svg viewBox="0 0 24 24"><path d="M9.19995 11.7H14.2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.7 14.2V9.19995" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 22L20 20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            <span>{{$t('MODAL_BTN_INV')}}</span></a>
                    </div>
                    <div class="grid-form-100">
                        <div class="form--input">
                             <label for="nome">{{$t('NAME')}}</label>            
                             <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.invoice.member.user.name">
                         </div>
                         <div class="form--input grid-form-60">
                            <label for="nome">{{$t('MODAL_FORM_CNPJ')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.invoice.cnpj">
                        </div>
                        <div class="form--input grid-form-40">
                            <label for="nome">{{$t('MODAL_FORM_DATE')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  :value="dataFormat(invoice.invoice.purchaseDate)">
                        </div>
                        <div class="form--input grid-form-60">
                            <label for="nome">{{$t('MODAL_FORM_INV')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.invoice.number">
                        </div>
                        <div class="form--input grid-form-40">
                            <label for="nome">{{$t('MODAL_FORM_VALUE')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.invoice.amount">
                        </div>             
                    </div>
                
                    
                </div>

                <div class="card__modal grid-form-100">
                    <legend class="grid-form-100">{{$t('MODAL_FORM_PROD')}}</legend>                
                    <div class="container--prod grid-form-100">
                        <div class="form--input grid-form-50">
                            <label for="nome">{{$t('NAME')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.product.name">
                        </div> 
                        <div class="form--input grid-form-50">
                            <label for="nome">{{$t('MODAL_FORM_MODEL')}}:</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.product.model.name">
                        </div> 
                        <div class="form--input grid-form-80">
                            <label for="nome">{{$t('MODAL_FORM_VALUE_PROD')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.amount">
                        </div> 
                        <div class="form--input grid-form-20">
                            <label for="nome">{{$t('MODAL_FORM_QTD')}}</label>            
                            <input :disabled="!isEdit" type="text" name="nome"  v-model="invoice.quantity">
                        </div> 
                        <!-- <div class="form--input grid-form-100">
                            <label for="nome">{{$t('USER_TYPE')}}:</label>            
                            <select name="campaign" id="" v-model="user.role.id">
                                <option v-for="(role, index) in roles" :key="index" :selected="user.role.id === role.id" :value="role.id">{{role.name}}</option>
                            </select>
                        </div> -->
                    </div>          
                </div>         
                <div class="card__modal grid-form-100" v-show="(action !== 'REJECTED' && !isEdit) || (isEdit && action === 'REJECTED')">
                    <div class="form--input reason">
                        <label for="reason" class="required">{{$t('REASON')}}</label>
                        <textarea type="text" id="reason" :class="[!invoice.reason.trim() && reasonFg? 'error':'' ]"  v-model="invoice.reason" name="reason"></textarea>
                        <span v-show="!invoice.reason.trim() && reasonFg" class="alert">{{$t('FILL_REASON')}}</span>       
                    </div>
                </div>
                <div v-if="'ANALYSIS' == action" class="card__modal modal__btns grid-form-100">
                    <button @click="cancelEdit" v-if="isEdit" type="button" class="button button--cancel">{{$t('CANCEL')}}</button>
                    <button @click="update" v-if="action !== 'ANALYSIS'" type="button" class="button">{{isSave?$t('SAVE'):$t('EDIT')}}</button>
                    <!-- <button @click="update" v-if="action !== 'ANALYSIS'" type="button" class="button">{{isEdit?'Save':'Edit'}}</button> -->
                    <button type="button" v-on:click="reject(invoice.id)" v-if="action !== 'REJECTED' && !isEdit" class="button button--red">{{$t('REJECT')}}</button>
                    <button :disabled="!invoice.productPointsId" type="button" v-on:click="approve(invoice.id)" v-if="action !== 'APPROVED' && !isEdit" class="button button--green">{{$t('APPROVE')}}</button>
                    <span v-if="!invoice.productPointsId" class="alert">Tabela de Pontos não encontrada para o Produto. Solicitar atualização ou rejeitar produto da nota fiscal.</span>       
                </div>
            </form>
        </template>
    </Modal>
        <section class="modulo list__grid architect--on-approved col-100">
            <header class="header-list">
                <div class="header-list--content">
                    <h4>{{$t('REG_TITLE_APRO')}}</h4>
                    <span class="amount">{{totalToApproved +' '+$t('REG_TITLE_APRO_TAG')}}</span> 
                </div>
                 <div class="content__form">
                    <input id="srch" v-model="sort.analyse.search" class="search" type="search" :placeholder="$t('SEARCH_NAME')">
                </div>      
            </header>
             <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                <thead>
                   <tr>
                        <th @click="setSortWait('user.name')"  class="row__head"><span>{{$t('USER')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortWait('user.email')" class="row__head"><span>{{$t('EMAIL')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortWait('createdAt')" class="row__head"><span>{{$t('JOIN')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>   
                        <!-- <th class="row__head">{{$t('ACTIONS')}}</th>             -->
                        <th class="row__head">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(inv, index) in invoicesToApprove" :key="index">
                        <td class="itens__description">{{inv.invoice.member?.user?.name}}</td>
                        <td class="itens__description">{{inv.invoice.member?.user?.email}}</td>
                        <td class="itens__description">{{dataFormat(inv.createdAt)}}</td>             
                        <td><a href="#modal-basic" class="button button--gray-stroke" @click="visual(inv, $event, 'ANALYSIS')">{{$t('APPROVE_REJECT')}}</a></td>
                    </tr>
                </tbody>
            </table>
              <div class="pagination">
                <span>{{(5*page.toAprove>=totalToApproved?totalToApproved:(page.toAprove<=1?page.toAprove:(page.toAprove*5)-5))+'-'+(5*page.toAprove>=totalToApproved?totalToApproved:5*page.toAprove)+' '+$t('OF')+' '+totalToApproved+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event, 'toAprove')" :disabled="page.toAprove <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event, 'toAprove')" href="#" v-show="page.toAprove > 1" class="btn--pagination">{{page.toAprove-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page.toAprove}}</a></li>
                        <li><a @click="nextPage($event, 'toAprove')" href="#" v-show="page.toAprove*5 < totalToApproved" class="btn--pagination">{{page.toAprove+1}}</a> </li>
                    </ul>
                    <button @click="nextPage($event, 'toAprove')" :disabled="page.toAprove*5 >= totalToApproved" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
            <!-- <a href="#" @click="moreWaitings($event)" class="more-items">{{this.skips.toAprove <= this.archsWait.length?'Load more items':'End'}}</a> -->
        </section>

        <section class="modulo list__grid archives architect--approved col-100">
            <header class="header-list">  
                <div class="header-list--content">
                    <h4>{{$t('REG_TITLE_APROVADOS')}}</h4>
                    <span class="amount">{{totalApproved +' '+$t('INVOICES')}}</span>                               
                </div>  
                <div class="content__form">
                    <input id="srch" v-model="sort.other.search" class="search" type="search" :placeholder="$t('SEARCH_NAME')">
                </div>                   
            </header>
            <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table v-if="invoiceNotToApprove && invoiceNotToApprove.length > 0" class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                
                <thead>
                    <tr>
                        <th @click="setSortOther('user.name')"  class="row__head"><span>{{$t('NAME')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('user.email')" class="row__head"><span>{{$t('EMAIL')}} <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('createdAt')" class="row__head"><span>{{$t('JOIN')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('createdAt')" class="row__head"><span>{{$t('TABLE_CAMP')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('invoiceProductStatus.id')"  class="row__head"><span>{{$t('STATUS')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th class="row__head">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(inv, index) in invoiceNotToApprove" :key="index">
                        <td class="itens__description">{{inv.invoice.member.user?.name}}</td>
                        <td class="itens__description">{{inv.invoice.member.user?.email}}</td>
                        <td class="itens__description">{{dataFormat(inv.createdAt)}}</td>
                        <th class="itens__description">Nome da Campanha</th>
                        <td class="itens__status">

                            <!-- 

                                ################################
                                ||                            ||
                                || Novos status para indicar  ||
                                ||      o processamento       ||
                                ||                            ||
                                ################################

                             -->
                            <template v-if="inv.invoiceProductStatusId == 3">
                                <span class="status__prod approved" data-tooltip="Enviando Pontos para Livelo..." data-flow="top">Aprovado
                                <i><svg fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.01 14.51c.18.3.4.58.65.83a4.732 4.732 0 006.68 0 4.71 4.71 0 001.32-2.67M7.34 11.33c.14-.98.57-1.92 1.32-2.67a4.732 4.732 0 016.68 0c.26.26.47.54.65.83"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M7.82 17.18v-2.67h2.67M16.18 6.82v2.67h-2.67"/></svg></i></span>
                            </template>
                            <template v-if="inv.invoiceProductStatusId == 4">
                                <span class="status__prod finish" data-tooltip="Pontos disponiveis na Livelo" data-flow="top">Finalizado 
                                <i><svg viewBox="0 0 48 48"><path fill="none" d="M0 0h48v48H0z" data-name="invisible box"/><path d="M24 2a22 22 0 1022 22A21.9 21.9 0 0024 2zm0 40a18 18 0 1118-18 18.1 18.1 0 01-18 18z"/><path d="M24 20a2 2 0 00-2 2v12a2 2 0 004 0V22a2 2 0 00-2-2z"/><circle cx="24" cy="14" r="2"/></svg></i></span>
                            </template>
                            <template v-if="inv.invoiceProductStatusId == 2">
                                <span class="status__prod rejected" :data-tooltip="inv.reason" data-flow="top">Rejeitado 
                                <i><svg viewBox="0 0 48 48"><path fill="none" d="M0 0h48v48H0z" data-name="invisible box"/><path d="M24 2a22 22 0 1022 22A21.9 21.9 0 0024 2zm0 40a18 18 0 1118-18 18.1 18.1 0 01-18 18z"/><path d="M24 20a2 2 0 00-2 2v12a2 2 0 004 0V22a2 2 0 00-2-2z"/><circle cx="24" cy="14" r="2"/></svg></i></span>
                            </template>
                          <!-- FINSH STATUS -->

                        </td>
                        <td><button @click="visual(inv, $event, inv.status?.name == 'APPROVED'?'APPROVED':'REJECTED')" class="button button--gray-stroke">{{$t('CAMP_BTN_CAD')}}</button> </td>
                        <!-- <td><a href="#modal-basic" class="button button--gray-stroke" @click="visual(arc, $event, 'APPROVED')">show profile</a></td> -->
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <span>{{(5*page.approve>=totalApproved?totalApproved:(page.approve<=1?page.approve:(page.approve*5)-5))+'-'+(5*page.approve>=totalApproved?totalApproved:5*page.approve)+' '+$t('OF')+' '+totalApproved+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event, 'approve')" :disabled="page.approve <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event, 'approve')" v-show="page.approve > 1" class="btn--pagination">{{page.approve-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page.approve}}</a></li>
                        <li><a @click="nextPage($event, 'approve')" v-show="page.approve*5 < totalApproved" class="btn--pagination">{{page.approve+1}}</a> </li>
                    </ul>     
                    <button @click="nextPage($event, 'approve')" :disabled="page.approve*5 >= totalApproved" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
        </section>
</template>


<script>
    import App from './registration.js'
    export default App;
</script>