import VideosService from '../services/videos.service';

export const videos = {
  namespaced: true,
  actions: {
    getContents({ commit }, params) {
      return VideosService.getContents(params[0]?.toString(), params[1]?.toString(), params[2]?.toString(), params[3]?.toString(), params[4]?.toString(), params[5]?.toString(), params[6]?.toString(), params[7]?.toString());
    },

    getContent({ commit }, id){
      return VideosService.getContent(id);
    }

    ,saveContents({ commit }, form){
      return VideosService.saveContents(form);
    }

    ,updateContents({ commit }, form){
      return VideosService.updateContents(form);
    }

    ,publisher({ commit }, vi){
      if(!vi.publishing)
        return VideosService.unpublish(vi.id);
      else
        return VideosService.publish(vi.id);
    }

    ,delete({ commit }, vi){
      return VideosService.delete(vi.id);
    }
  },
  mutations: {}
};
