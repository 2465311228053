import api from "./api";
import TokenService from "./token.service";
import router from '@/router'

class AuthService {
  login(user) {
    return api
      .post('auth/login', {
        email: user.username,
        password: user.password,
        token: user.token
      })
      .then(response => {
        if (response.data.access_token) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  token(username) {
    return api
      .post('auth/token', {
        email: username
      })
      .then(response => {
        return response.data;
      });
  }

  reset(username) {
    return api
      .post('auth/reset-password', {
        email: username
      })
      .then(response => {
        return response.data;
      });
  }

  logout() {
    api.get('auth/logout').then((response, status) => {
      if (status == 204)
        console.log("Logout success");
    }).catch(err =>{
      console.log(err);
    })
    .finally(function() { 
      TokenService.removeUser();
      router.push('/'); 
    });
  }

  register(user) {
    return api.post('auth/signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
  refreshToken() {
    return api.get("auth/refresh").then(response => {
      if(response.status == 401){
        TokenService.removeUser();
        router.push('/');
        return response?.data;
      }

      if (response.data.access_token) {
        TokenService.setUser(response.data);
      }

      return response.data;
    },
    error =>{
      if(error.response?.status == 401){
        TokenService.removeUser();
        router.push('/');
        return response?.data;
      }
    }).catch(err =>{
      TokenService.removeUser();
      router.push('/');
      return Promise.reject(err);
    });
  }

  refreshTokenAsync() {
    return api.get("auth/refresh");
  }

  async changeLang(id, lang){
    await api.put('users/'+id, {language: lang}).then(
      response =>{
        TokenService.setLang(response.data?.language);
        return response.data;
      }
    );
  }
}

export default new AuthService();
