import axios from "./api";

class ArchitectService {

  getDashboard(dt1, dt2) {
    var filter ='?'
    if(dt1 && dt1 !== '')
      filter+='dtStart='+dt1+'&'
    if(dt2 && dt2 !== '')
      filter+='dtFinish='+dt2+'&'
    return axios.get('/dashboard'+filter);
  }
}

export default new ArchitectService();
