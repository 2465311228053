<template>
<!-- DIALOGE BOX =============== -->
	<div class="dialog__overlay" v-if="confirmDialog.show">
		<div class="dialog__container">
			<div class="dialog__container--text">
				<h3>{{$t('MODAL_ALERT_TITLE')}}</h3>
				<p>{{$t('MODAL_ALERT_DEC')}}</p>
			</div> 
			<div class="dialog__container--btns">
				<button type="button" class="button button--cancel" @click="confirmDialog.show = false; confirmDialog.id = 0;">Cancel</button>
				<button type="button" class="button button--add" @click="remove(null, null, false)">Confirm</button>
			</div>		
		</div>
	</div>
<!-- DIALOGE BOX =============== -->

    <section class="wrapper">
        <div class="modulo col-100">
            <form action="">               
                   <div class="form--input grid-form-100">
                        <label for="title-alt" id="ttle" class="required">{{$t('TITLE_MAIN')}}</label>
                        <input type="text" id="title-alt" name="title-alt" class="" v-model="form.title">
                        <span v-show="sending && !formValid.title" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                    </div>                             
                    <div class="form--input grid-form-50">
                        <label for="file-d" class="required">{{$t('CAMP_FORM_REG')}} <span>(.PDF)</span></label>
                        <input type="file" id="file-d" name="file-d" class="custom-file-input" @change="uploadFileDesktop($event, form.fileImage, form.imageName)">
                        <span v-show="sending && !formValid.fileImage" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                        <span v-show="formAction == 'EDIT'" style="color: red; font-size: 12px; font-family: 'Arial';">{{$t('BANNER_INFO')}}</span>
                    </div>

                    <div class="form--input grid-form-25">
                        <label for="title-alt" id="ttle" class="required">{{$t('CAMP_FORM_START')}}</label>
                        <input type="date" id="title-alt" name="title-alt" class="" v-model="form.title">
                        <span v-show="sending && !formValid.title" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                    </div> 
                    <div class="form--input grid-form-25">
                        <label for="title-alt" id="ttle" class="required">{{$t('CAMP_FORM_END')}}</label>
                        <input type="date" id="title-alt" name="title-alt" class="" v-model="form.title">
                        <span v-show="sending && !formValid.title" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                    </div> 


                    <div class="content--legend--btn grid-form-100">
                        <legend class="legend--subform grid-form-100">{{$t('CAMP_FORM_TITLE_PROD')}}</legend>
                        <button class="button add--prod">+ {{$t('CAMP_BTN_PROD')}}</button>
                    </div>
                   
                   
                    <ul class="list--prod grid-form-100">
                        <li  class="prod--campaing grid-form-100">
                            <div class="form--input grid-form-40">
                                <label for="category" class="required">{{$t('CAMP_FORM_LABEL_CAT')}}</label>
                                <select v-model="form.showMainNews" name="Category" id="" >
                                    <option value="MODELO">TVS OLED</option>
                                    <option value="MODELO">AR Condicionado</option>
                                </select>
                                <span v-show="sending && !formValid.showMainNews" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                            <div class="form--input grid-form-40">
                                <label for="category" class="required"> {{$t('CAMP_FORM_LABEL_MOD')}}</label>
                                <select v-model="form.showMainNews" name="Category" id="" >
                                    <option :value="true">{{$t('YES')}}</option>
                                    <option :value="false">{{$t('NO')}}</option>
                                </select>
                                <span v-show="sending && !formValid.showMainNews" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                            <div class="form--input grid-form-20">
                                <label for="title-alt" id="ttle" class="required"> {{$t('CAMP_FORM_LABEL_PONT')}}</label>
                                <input type="text" id="title-alt" name="title-alt" class="" v-model="form.title">
                                <span v-show="sending && !formValid.title" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                        </li>
                        <li class="prod--campaing grid-form-100">
                            <button class="btn--del"><svg viewBox="0 0 1024 1024"><path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/></svg></button>
                            <div class="form--input grid-form-40">
                                <label for="category" class="required">{{$t('CAMP_FORM_LABEL_CAT')}}</label>
                                <select v-model="form.showMainNews" name="Category" id="" >
                                    <option value="MODELO">TVS OLED</option>
                                    <option value="MODELO">AR Condicionado</option>
                                </select>
                                <span v-show="sending && !formValid.showMainNews" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                            <div class="form--input grid-form-40">
                                <label for="category" class="required">{{$t('CAMP_FORM_LABEL_MOD')}}</label> 
                                <select v-model="form.showMainNews" name="Category" id="" >
                                    <option :value="true">{{$t('YES')}}</option>
                                    <option :value="false">{{$t('NO')}}</option>
                                </select>
                                <span v-show="sending && !formValid.showMainNews" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                            <div class="form--input grid-form-20">
                                <label for="title-alt" id="ttle" class="required"> {{$t('CAMP_FORM_LABEL_PONT')}}</label>
                                <input type="text" id="title-alt" name="title-alt" class="" v-model="form.title">
                                <span v-show="sending && !formValid.title" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                        </li>
                        <li class="prod--campaing grid-form-100">
                            <button class="btn--del"><svg viewBox="0 0 1024 1024"><path d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/></svg></button>
                            <div class="form--input grid-form-40">
                                <label for="category" class="required">{{$t('CAMP_FORM_LABEL_CAT')}}</label>
                                <select v-model="form.showMainNews" name="Category" id="" >
                                    <option :value="true">{{$t('YES')}}</option>
                                    <option :value="false">{{$t('NO')}}</option>
                                </select>
                                <span v-show="sending && !formValid.showMainNews" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                            <div class="form--input grid-form-40">
                                <label for="category" class="required"> {{$t('CAMP_FORM_LABEL_MOD')}}</label>
                                <select v-model="form.showMainNews" name="Category" id="" >
                                    <option :value="true">{{$t('YES')}}</option>
                                    <option :value="false">{{$t('NO')}}</option>
                                </select>
                                <span v-show="sending && !formValid.showMainNews" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                            <div class="form--input grid-form-20">
                                <label for="title-alt" id="ttle" class="required"> {{$t('CAMP_FORM_LABEL_PONT')}}</label>
                                <input type="text" id="title-alt" name="title-alt" class="" v-model="form.title">
                                <span v-show="sending && !formValid.title" class="alert--form">{{$t('FORM_ALERT_SPAN')}}</span> 
                            </div>
                        </li>
                        </ul>


                <!-- PRODUTOS ============= -->
                <div class="grid-form-40">
                    <div class="form--input disclaimer">
                        <p class="required">{{$t('REQUIRED_2')}}</p>
                    </div>
                </div>
                <div class="grid-form-60">
                    <div class="form--input edit-text">
                        <button type="button" class="button button--cancel" v-show="this.formAction=='EDIT'" @click="this.form = {showMainNews: true}; this.formAction='CREATE'; emptyHtml(); resetTokens();">{{$t('CANCEL')}}</button>
                        <button type="button" class="button button--red" @click="saveUpdate()">{{formAction == 'EDIT'?$t('SAVE'):$t('FINISH')}}</button>
                    </div>  
                </div>
            </form>
        </div>
        <section class="modulo list__grid archives architect--approved col-100">
            <header class="header-list">  
                <div class="header-list--content">
                    <h4>Campanhas</h4>
                    <span class="amount">{{total + ' '+ $t('ITEMS')}}</span>                               
                </div>  
                <div class="content__form">
                    <input id="srch" class="search" type="search" :placeholder="$t('SEARCH_USER')" v-model="sort.search">
                </div>                   
            </header>
            <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table v-if="lista && lista.length > 0" class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                <thead>
                    <tr>
                        <th class="row__head"><span>{{$t('CAMP_TABELA_TITLE')}}</span></th>
                        <th @click="setSort('news.title')"  class="row__head"><span>{{$t('CAMP_TABELA_TITLE_2')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>                        
                        <th @click="setSort('news.publish')" class="row__head"><span>{{$t('STATUS')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('news.publish')"  class="row__head"><span>{{$t('PUBLISH')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <!-- <th class="row__head">{{$t('ACTIONS')}}</th>             -->
                        <th class="row__head">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(news, index) in lista" :key="index">
                      
                        <td class="itens__description">Descrição Campanha</td>
                        <td class="itens__status">{{dataFormat(news.createdAt)}}</td>
                        <td class="itens__status" :class="{published : news.publish, 'not-published' : !news.publish}">{{news.publish?$t('PUBLISHED'):$t('UNPUBLISHED')}}</td>
                        <td class="itens__input"><input type="checkbox" v-model="news.publish"></td>
                        <!-- <td class="itens__edit" align="center">
                            <div class="edit__list">
                                <a alt="Edit" title="Edit" href="#" @click="edit($event, news.id)" class="itens__edit--document" >
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                                       <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M11 2H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6c5 0 7-2 7-7v-2"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M16.04 3.02L8.16 10.9c-.3.3-.6.89-.66 1.32l-.43 3.01c-.16 1.09.61 1.85 1.7 1.7l3.01-.43c.42-.06 1.01-.36 1.32-.66l7.88-7.88c1.36-1.36 2-2.94 0-4.94-2-2-3.58-1.36-4.94 0zM14.91 4.15a7.144 7.144 0 004.94 4.94"/></svg>
                                    </svg>
                                </a>
                                <a alt="Remove" title="Remove" href="#" @click="remove($event, news.id, true)" class="itens__edit--delete">
                                   <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" d="M17.9 9.05a65.54 65.54 0 00-6.57-.33c-1.3 0-2.6.07-3.89.2l-1.34.13M9.71 8.39l.14-.86c.1-.62.18-1.09 1.29-1.09h1.72c1.11 0 1.19.49 1.29 1.09l.14.85M16.49 9.13l-.43 6.6c-.07 1.03-.13 1.83-1.96 1.83H9.89c-1.83 0-1.89-.8-1.96-1.83l-.43-6.6"/></svg>
                                </a>
                            </div>
                        </td> -->
                        <td alt="Apply" title="Apply" align="right"><button @click="applyPublish(news.id)" class="button button--gray-stroke">{{$t('APPLY')}}</button></td>
                    </tr>
                </tbody>
            </table>

            <div class="pagination">
                <span>{{(5*page>=total?total:(page<=1?page:(page*5)-5))+'-'+(5*page>=total?total:5*page)+' '+$t('OF')+' '+total+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event)" :disabled="page <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event)" href="#" v-show="page > 1" class="btn--pagination">{{page-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page}}</a></li>
                        <li><a @click="nextPage($event)" href="#" v-show="page*5 < total" class="btn--pagination">{{page+1}}</a> </li>
                    </ul>
                    <button @click="nextPage($event)" :disabled="page*5 >= total" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
//import "@/assets/logged/app.min.css";
import dt from 'date-and-time';
import {Base64} from 'js-base64';
export default {
    props: {
        search: String,
        propiedades: Object
    },
    data(){
        return{
            confirmDialog: {
                show: false,
                id: 0
            },
            loading:{
                approved: {
                    show: false,
                    msg: '',
                    loading: ''
                },
                rejected: {
                    show: false,
                    msg: '',
                    loading: ''
                },
                toAprove: {
                    show: false,
                    msg: '',
                    loading: ''
                }
            },
            form:{
                active: true,
                showMainNews: true,
                title: "",
                subTitle: "",
                content: "",
                fileImageTumbler: "",
                imageTumblerName: "",
                fileImage: "",
                imageName: "",
                keyNews: []
            },
            lista:[],
            formAction : 'CREATE',
            sort:{
                field:'news.createdAt',
                order:'desc',
                search: '',
                limit: 5,
                skip: 0,
            },
            total: 0,
            page: 1,
            baseUrl: process.env.VUE_APP_API_URL,
            tokenAutocomplete:null,
            summnt:false,
            sending: false,
            observer: null
        }
    },
    watch: {
        search : function(val){
            this.filtraTexto();
        }
    },
    computed: {
        formValid : function(){
            return{
                showMainNews: typeof true === "boolean",
                title: !!this.form.title && this.form.title.length > 0,
                subTitle: !!this.form.subTitle && this.form.subTitle.length > 0,
                content: !!this.form.content && this.form.content.length > 0,
                fileImageTumbler: !!this.form.fileImageTumbler && this.form.fileImageTumbler.length > 0,
                fileImage: !!this.form.fileImage && this.form.fileImage.length > 0,
                keyNews: !!this.form.keyNews && this.form.keyNews.length > 0,
            }
        },

        isFormValid : function(){
            var ret = true
            Object.entries(this.formValid).forEach(([key, value]) => {
                if(!value){
                    ret = false;
                }
            });
            return ret;
        },

        isTagsValid : function(){
          if(!document.getElementById('sample-select'))
            return false;
          var tags = document.getElementById('sample-select').options;
          if(!tags || tags.length <= 0 )
            return false;
        return true;
      },
    },
		created() {
            document.title= 'Campanhas | ' + document.title.substring(document.title.indexOf('|') + 1)
            this.$store.dispatch("auth/alterMenu", "Campanhas");
            this.propiedades.descricao = 'Cadastre as campanhas de pontos para os participantes'
            // this.propiedades.helperTitle = 'After conclude the news information, remember to publish.'
            // this.propiedades.helperDesc = 'The news will be shown automaticaly on the portal'+
            // '<br/>The “Tags” are important to simplify the search on the text news'
            this.listNews();
		},
        watch: {
            'sort.search' : function(val){
                this.page=1,
                this.skip=0;
                this.listNews();
            }
        },
mounted(){
	  var self=this;
	  if (document.readyState === "complete" || document.readyState === "loaded") {
	 // document is already ready to go
     self.resetTokens();
    
    document.getElementById('file-d').value = ''
    document.getElementById('file-m').value = ''
    if(this.summnt)
	    self.emptyHtml();
    else
        self.editor();
    //self.tags();
	}else
	 document.addEventListener("DOMContentLoaded", function() {
      self.resetTokens();
    if(this.summnt)
	    self.emptyHtml();
    else
        self.editor();
    document.getElementById('file-d').value = ''
    document.getElementById('file-m').value = ''
	 });

    //  this.observer = new MutationObserver(function(mutations) {
    //         self.validTags();
    //     }.bind(this));
    //     // Setup the observer
    //     this.observer.observe(
    //         document.getElementById('sample'),
    //         { attributes: true, childList: true, characterData: true, subtree: true }
    //     );
  },
 
  methods: {
    nextPage(e, type){
        e.preventDefault();
        this.page+=1;
        this.sort.skip+=5;
        this.listNews();
    },
    previousPage(e, type){
        e.preventDefault();
        this.page-=1;
        this.sort.skip-=5;
        this.listNews();
    },
      validTags(){
          this.form.keyNews = []
          if(!document.getElementById('sample-select'))
            return false;
          var tags = document.getElementById('sample-select').options;
          if(!tags || tags.length <= 0 )
            return false;
          for (let index = 0; index < tags.length; index++){
            this.form.keyNews.push({value:tags[index].value});
          }
            return true;
      },
      setHtml(){
          const xss = require("xss");
          var options = {
              css:false,
          whiteList: {
            a:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            i:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            img:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            h2:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            p:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            h3:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            br:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            b:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            u:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            iframe:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            table:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            tbody:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            tr:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            td:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            ol:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            li:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            ul:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            span:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            font:['frameborder', 'src', 'width', 'height', 'class', 'style', 'data-filename', "href", "title", "target", 'float', 'color'],
            
          },
        };
            var a = $('#summernote').summernote('code');
          this.form.content = xss(a, options);
          console.log(this.form.content)
          this.form.content = '##BASE64##'+Base64.encode(this.form.content);
      },
      emptyHtml(){
          $('#summernote').summernote('code', "");
      },
      saveUpdate(){
          this.validTags();
          this.setHtml();
          this.sending = true;
          if(this.isFormValid){
              this.sending = false;  
            if(this.formAction == 'EDIT'){
                var fl1 = document.getElementById('file-d').value
                var fl2 = document.getElementById('file-m').value
                delete this.form.imageName
                delete this.form.imageTumblerName
                if(fl1 || fl1 == ''){
                    delete this.form.fileImage;
                }if(fl2 || fl2 == ''){
                    delete this.form.fileImageTumbler;  
                }this.$store.dispatch("news/update", this.form).then((resp) =>{
                    this.listNews();
                    this.setFeedback('News atualizada com sucesso!', 'SUCCESS');
                    this.form = {showMainNews: true};
                    this.formAction='CREATE'
                    this.resetTokens();
                    this.emptyHtml();
                    document.getElementById('file-d').value = ''
                    document.getElementById('file-m').value = ''
                }).catch(e =>{
                    console.log(e);
                    if(e?.response?.data)
                        this.setFeedback(e.response.data.message, 'DANGER');
                    else
                        this.setFeedback('Falha ao atualizar a news!', 'DANGER');
                });
            }else{
                this.$store.dispatch("news/create", this.form).then((resp) =>{
                    this.listNews();
                    this.setFeedback('News criada com sucesso!', 'SUCCESS');
                    this.form = {showMainNews: true};
                    this.resetTokens();
                    this.emptyHtml();
                    document.getElementById('file-d').value = ''
                    document.getElementById('file-m').value = ''
                }).catch(e =>{
                    console.log(e);
                    if(e?.response?.data)
                        this.setFeedback(e.response.data.message, 'DANGER');
                    else
                        this.setFeedback('Falha ao criar a news!', 'DANGER');
                });
            }
        }else
            this.setFeedback('Campos obrigatórios não preenchidos.', 'DANGER');
      },
      applyPublish(id){
          if(this.lista.find(function(obj){return obj.id == id}).publish){
              this.$store.dispatch("news/publish", id).then((resp) =>{
                this.listNews();
                this.setFeedback('News publicada com sucesso!', 'SUCCESS');
            }).catch(e =>{
                if(e.response.data)
                    this.setFeedback('e.response.data.message', 'DANGER');
                else
                    this.setFeedback('Falha ao publicar news!', 'DANGER');
            });
          }else
            this.$store.dispatch("news/unpublish", id).then((resp) =>{
                this.listNews();
                this.setFeedback('Sucesso ao despublicar a news!', 'SUCCESS');    
            }).catch(e =>{
                if(e.response.data)
                    this.setFeedback(e.response.data.message, 'DANGER');
                else
                    this.setFeedback('Falha ao despublicar a news!', 'DANGER');    
            });
      },
      edit(e, id){
          e.preventDefault();
          this.sending = false;
          this.formAction = 'EDIT'
            this.$store.dispatch("news/getNewsId", id).then((resp) =>{
                this.form = resp.data;
                this.form.fileImageTumbler = this.form.imageTumbler
                this.form.fileImage = this.form.image
                if(this.form.content.indexOf('##BASE64##')>=0){
                    this.form.content = this.form.content.replace('##BASE64##', '');  
                    this.form.content = Base64.decode(this.form.content)
                } 
                $('#summernote').summernote('code', this.form.content);
                document.getElementById('sample').innerHTML = "";
                var initialToken = []
                if(this.form.keyNews && this.form.keyNews.length > 0){
                    for (let index = 0; index < this.form.keyNews.length; index++) 
                        initialToken.push({value:this.form.keyNews[index].value, text:this.form.keyNews[index].value})
                    }
               this.tokenAutocomplete = new TokenAutocomplete({
                name: 'sample',
                selector: '#sample',
                noMatchesText: 'No matching results...',
                initialTokens: initialToken,
                initialSuggestions: []
            });
            this.editor();
            window.location.href = "#ttle"
           });
      },
      resetTokens(initialToken){
          if(!initialToken)
            initialToken = []
        document.getElementById('sample').innerHTML = "";
          this.tokenAutocomplete = new TokenAutocomplete({
                name: 'sample',
                selector: '#sample',
                noMatchesText: 'No matching results...',
                initialTokens: initialToken,
                initialSuggestions: []
            });
      },
      remove(e, id, dialog){
          if(dialog){
              e.preventDefault();
              this.confirmDialog.show = true;
              this.confirmDialog.id = id;
              return;
          }
          this.$store.dispatch("news/deleteNews", this.confirmDialog.id).then((resp) =>{
            this.listNews();
            this.setFeedback('News removida com sucesso!', 'SUCCESS');
        }).catch(e =>{
            if(e.response.data)
                this.setFeedback(e.response.data.message, 'DANGER');
            else
                this.setFeedback('Falha ao remover news!', 'DANGER');
            });
            this.confirmDialog.show = false;
              this.confirmDialog.id = 0;
      },
      dataFormat(data){
        try{
            return dt.format(dt.parse(data, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), 'DD-MM-YYYY')
        }catch(e){
            return ''
        }
      },
      listNews(){
          this.$store.dispatch("news/getNews", [this.sort.limit, this.sort.skip, null, null, this.sort.field, this.sort.order, this.sort.search]).then((resp) =>{
            this.lista = resp.data.data;
            this.total = resp.data.totalCount
          });
      },
	  editor(){
			$('#summernote').summernote({
                callbacks: {
                    onPaste: function (e) {
                        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    }
                },
                height: 150,
                minHeight: 260, 
                maxHeight: null,
                minWidth: 300,
                focus: true,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['paragraph', 'ul', 'ol']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture', 'video']],  
                ],
                styleTags: ['p', 'h2', 'h3'],            
            });
            this.summnt = true;
	  },
        tags(){
             this.tokenAutocomplete = new TokenAutocomplete({
                name: 'sample',
                selector: '#sample',
                noMatchesText: 'No matching results...',
                initialTokens: this.form.keyNews,
                initialSuggestions: [
                    {value: 'apple', text: 'Apple'},
                    {value: 'banana', text: 'Banana'},
                    {value: 'donut', text: 'Donut'},
                    {value: 'pizza', text: 'Pizza'},
                    {value: 'burger', text: 'Burger'},
                    {value: 'pancake', text: 'Pancake'}
                ]
            });
            //tokenAutocomplete.debug(true);
			
	  },

    uploadFileDesktop(e) {
        var self = this;
        var reader = new FileReader();
        var file = e.target.files[0];
        self.form.imageName = file.name;
        if (e.target.files.length > 0) {
            reader.readAsDataURL(file);
            reader.onload = function (evt) {
                self.form.fileImage = reader.result.split(',')[1]
            }
        }
    },

     uploadFileMobile(e) {
         var self = this;
        var reader = new FileReader();
        var file = e.target.files[0];
        self.form.imageTumblerName = file.name;
        if (e.target.files.length > 0) {
            reader.readAsDataURL(file);
            reader.onload = function (evt) {
                self.form.fileImageTumbler = reader.result.split(',')[1]
            }
        }
    },
    setSort(field){
        this.sort.field = field;
        this.sort.order = this.sort.order==='desc'?'asc':'desc';
        this.page=1
        this.skip=0;
        this.listNews(); 
    },
    setFeedback(m, s){
        this.propiedades.feedback.message = ''
        this.propiedades.feedback.status=''
        this.propiedades.feedback.message = m
        this.propiedades.feedback.status=s
    },
  }
  
}
</script>

