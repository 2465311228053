<template>
    <section class="wrapper">
        <div class="modulo col-100">
            <form action="">
                <div class="grid-form-70">
                    <div class="form--input">
                        <label for="title-alt" id="ttle" class="required">{{$t('TITLE_MAIN')}}</label>
                        <input type="text" id="title-alt" name="title-alt" class="" v-model="form.title">
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.title) || (formAction !== 'EDIT' && !formValid.title))" class="alert--form">Campo obrigatório</span> 
                    </div>

                    <div class="form--input">
                        <label for="subtitle-alt" id="subttle" class="required">{{$t('SUMMARY_VIDEOS')}}</label>
                        <input type="text" id="subtitle-alt" name="subtitle-alt" class="" v-model="form.legend">
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.legend) || (formAction !== 'EDIT' && !formValid.legend))" class="alert--form">Campo obrigatório</span> 
                    </div>
              
                    <div class="form--input">
                        <label for="avatar" class="required">{{$t('DESC')}}</label>
                        <textarea id="summernote" name="editordata"></textarea>
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.textContent) || (formAction !== 'EDIT' && !formValid.textContent))" class="alert--form">Campo obrigatório</span> 
                    </div>
                </div>
                 <div class="grid-form-30">
                    <div class="form--input">
                        <label for="category" class="required">{{$t('BANNER_MAIN')}}</label>
                        <select v-model="form.showMainContents" name="Banner" id="" >
                            <option :value="true">{{$t('YES')}}</option>
                            <option :value="false">{{$t('NO')}}</option>
                        </select>
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.showMainContents) || (formAction !== 'EDIT' && !formValid.showMainContents))" class="alert--form">Campo obrigatório</span> 
                    </div>
                    <div class="form--input">
                        <label for="category" class="required">{{$t('CATEGORY')}}</label>
                        <select v-model="form.category" name="Category" id="" >
                            <!-- <option :value="1">{{$t('AUTOCAD')}}</option> -->
                            <option :value="2">{{$t('PRODUTOS_LG')}}</option>
                            <option :value="3">{{$t('LG_MAGENTA')}}</option>
                        </select>
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.category) || (formAction !== 'EDIT' && !formValid.category))" class="alert--form">Campo obrigatório</span> 
                    </div>
                     <div class="form--input">
                        <label for="title-alt" ><span class="required">{{$t('TAGS')}}</span> <span class="disclaimer">Press enter to include your tag</span></label>
                        <div id="sample"></div>
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.keywords) || (formAction !== 'EDIT' && !formValid.keywords))" class="alert--form">Campo obrigatório</span> 
                    </div>
                </div>
                <div class="grid-form-70">
                    <div class="form--input">
                        <label for="url" class="required">{{$t('VIDEO_LINKS')}}</label>
                        <input type="text" id="url" name="url" class="" v-model="form.linkUrl">
                        <span v-show="sending && ((formAction === 'EDIT' && !formValidEdit.linkUrl) || (formAction !== 'EDIT' && !formValid.linkUrl))" class="alert--form">Campo obrigatório</span> 
                    </div>
                </div>
                <div class="grid-form-70">
                    <div class="form--input">
                        <label for="file-d" class="required">{{$t('BANNER_DESKTOP')}} <span>(1600px X 800px)</span></label>
                        <input type="file" id="file-d" name="file-d" class="custom-file-input" @change="uploadFileDesktop($event)">
                        <span v-show="formAction == 'EDIT'" style="color: red; font-size: 12px; font-family: 'Arial';">{{$t('BANNER_INFO')}}</span>
                        <span v-show="sending && formAction !== 'EDIT' && !formValid.image" class="alert--form">Campo obrigatório</span> 
                    </div>
                    <div class="form--input">
                        <label for="file-m" class="required">{{$t('BANNER_MOBILE')}} <span>(768 X 1180px)</span></label>
                        <input type="file" id="file-m" name="file-m" class="custom-file-input" @change="uploadFileMobile($event)">
                        <span v-show="formAction == 'EDIT'" style="color: red; font-size: 12px; font-family: 'Arial';">{{$t('BANNER_INFO')}}</span>
                        <span v-show="sending && formAction !== 'EDIT' && !formValid.imageMobile" class="alert--form">Campo obrigatório</span> 
                    </div>

                    <div class="form--input">
                        <label for="file-t" class="required">{{$t('THUMB')}} <span>(260 X 365px)</span></label>
                        <input type="file" id="file-t" name="file-t" class="custom-file-input" @change="uploadFileThumb($event)">
                        <span v-show="formAction == 'EDIT'" style="color: red; font-size: 12px; font-family: 'Arial';">{{$t('BANNER_INFO')}}</span>
                        <span v-show="sending && formAction !== 'EDIT' && !formValid.thumbImage" class="alert--form">Campo obrigatório</span> 
                    </div>
                </div>
                <div class="grid-form-40">
                    <div class="form--input disclaimer">
                        <p class="required">{{$t('REQUIRED_2')}}</p>
                    </div>
                </div>
                <div class="grid-form-60">
                    <div class="form--input edit-text">
                        <button type="button" class="button button--cancel" v-if="this.formAction=='EDIT'" @click="resetForm(); emptyHtml(); resetTokens();">{{$t('CANCEL')}}</button>
                        <button v-if="formAction !== 'EDIT'" type="button" class="button button--red" @click="send()">{{$t('FINISH')}}</button>
                         <button v-if="formAction === 'EDIT'" type="button" class="button button--red" @click="edit()">{{$t('EDIT')}}</button>
                        <!-- {{formAction == 'EDIT'?'Save':'Finish'}} -->
                    </div>
                </div>
            </form>
        </div>
        <section class="modulo list__grid archives architect--approved col-100">
            <header class="header-list">  
                <div class="header-list--content">
                    <h4>Videos</h4>
                    <span class="amount">{{filtros.total +' '+$t('ITEMS')}}</span>                               
                </div>  
                <div class="content__form">
                    <input id="srch" class="search" type="search" :placeholder="$t('SEARCH_USER')" v-model="filtros.search">
                </div>                   
            </header>
            <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table v-if="videos && videos.length > 0" class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                <thead>
                    <tr>
                        <th @click="setSort('co.title')"  class="row__head"><span>{{$t('TITLE')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('co.category')" class="row__head"><span>{{$t('CATEGORY')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('co.createdAt')" class="row__head"><span>{{$t('CREATE_DATE')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('co.publish')" class="row__head"><span>{{$t('STATUS')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('co.publish')"  class="row__head"><span>{{$t('PUBLISH')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <!-- <th @click="setSort('us.blocked')"  class="row__head"><span>Status<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th> -->
                        <th class="row__head">{{$t('ACTIONS')}}</th>
                        <th class="row__head">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(dw, index) in videos" :key="index">
                        <td class="itens__description">{{dw.title}}</td>
                        <td class="itens__description">{{dw.category?.name}}</td>
                        <td class="itens__description">{{dataFormat(dw.createdAt)}}</td>
                        <td class="itens__status" :class="{published : dw.publish, 'not-published' : !dw.publish}">{{dw.publish?$t('PUBLISHED'):$t('UNPUBLISHED')}}</td>
                        <td class="itens__input"><input type="checkbox" @change="preChangePublish(dw, $event)" :checked="dw.publish"></td>
                        <!-- <td><a href="#modal-basic" class="button button--gray-stroke" @click="visual(arc, $event, 'APPROVED')">show profile</a></td> -->
                        <td class="itens__edit" align="center">
                            <div class="edit__list">
                                <a alt="Edit" title="Edit" href="#" @click="editing(dw)" class="itens__edit--document" >
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                                       <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M11 2H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6c5 0 7-2 7-7v-2"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M16.04 3.02L8.16 10.9c-.3.3-.6.89-.66 1.32l-.43 3.01c-.16 1.09.61 1.85 1.7 1.7l3.01-.43c.42-.06 1.01-.36 1.32-.66l7.88-7.88c1.36-1.36 2-2.94 0-4.94-2-2-3.58-1.36-4.94 0zM14.91 4.15a7.144 7.144 0 004.94 4.94"/></svg>
                                    </svg>
                                </a>
                                <a alt="Remove" title="Remove" href="#" @click="deleteDW(dw)" class="itens__edit--delete">
                                   <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" d="M17.9 9.05a65.54 65.54 0 00-6.57-.33c-1.3 0-2.6.07-3.89.2l-1.34.13M9.71 8.39l.14-.86c.1-.62.18-1.09 1.29-1.09h1.72c1.11 0 1.19.49 1.29 1.09l.14.85M16.49 9.13l-.43 6.6c-.07 1.03-.13 1.83-1.96 1.83H9.89c-1.83 0-1.89-.8-1.96-1.83l-.43-6.6"/></svg>
                                </a>
                            </div>
                        </td>
                        <td align="right"><button @click="changePublish(dw)" class="button button--gray-stroke">{{$t('APPLY')}}</button></td>
                        <!-- <td><button @click.prevent="editing(dw)" class="button button--gray-stroke">{{$t('EDIT')}}</button> </td> -->
                    </tr>
                </tbody>
            </table>

            <div class="pagination">
                <span>{{(5*page>=filtros.total?filtros.total:(page<=1?page:(page*5)-5))+'-'+(5*page>=filtros.total?filtros.total:5*page)+' '+$t('OF')+' '+filtros.total+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event)" :disabled="page <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event)" href="#" v-show="page > 1" class="btn--pagination">{{page-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page}}</a></li>
                        <li><a @click="nextPage($event)" href="#" v-show="page*5 < filtros.total" class="btn--pagination">{{page+1}}</a> </li>
                    </ul>
                    <button @click="nextPage($event)" :disabled="page*5 >= filtros.total" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
        </section>
    </section>
</template>
<script>
//import "@/assets/logged/app.min.css";
import dt from 'date-and-time';
export default {
    props: {
        search: String,
        propiedades: Object
    },
    data(){
        return{
            observer: null,
            sending: false,
            formAction:'',
            form:{
                contentTypes: 2,  
                active: true,
                title: "",
                textContent:"",
                legend: "",
                showMainContents: true,
                category:1,
                keywords: [],
                image:{},
                imageMobile:{},
                linkUrl:'',
                thumbImage:{}
            },
            videos:[],
            filtros:{
              order:'DESC',
              skip:0,
              limit:5,
              categoria:null,
              search:'',
              sort:'co.createdAt',
              total: 0,
              typeId: 2,
              showMain: null
            },
            page:1,
            loading:{
                approved: {
                    show: false,
                    msg: '',
                    loading: ''
                },
                rejected: {
                    show: false,
                    msg: '',
                    loading: ''
                },
                toAprove: {
                    show: false,
                    msg: '',
                    loading: ''
                }
            },
            baseUrl: process.env.VUE_APP_API_URL,
            tokenAutocomplete:null,
        }
    },
    watch: {
        'filtros.search' : function(val){
            this.page=1,
            this.filtros.skip = 0;
            this.getVideos();
        },
    },
    computed: {
        isListEnded : function(){
            return this.filtros.total && this.filtros.skip >= this.filtros.total
        },

        formValid : function(){
            return{
                showMainContents: typeof true === "boolean",
                title: !!this.form.title && this.form.title.length > 0,
                legend: !!this.form.legend && this.form.legend.length > 0,
                textContent: !!this.form.textContent && this.form.textContent.length > 0,
                image: !!this.form.image.file && this.form.image.file.length > 0,
                imageMobile: !!this.form.imageMobile.file && this.form.imageMobile.file.length > 0,
                thumbImage: !!this.form.thumbImage.file && this.form.thumbImage.file.length > 0,
                keywords: !!this.form.keywords && this.form.keywords.length > 0,
                category: !!this.form.category && this.form.category > 0 && this.form.category < 4,
                linkUrl: !!this.form.linkUrl && this.form.linkUrl.length > 0,
            }
        },

        isFormValid : function(){
            var ret = true
            Object.entries(this.formValid).forEach(([key, value]) => {
                if(!value){
                    ret = false;
                }
            });
            return ret;
        },

        formValidEdit : function(){
            return{
                showMainContents: typeof true === "boolean",
                title: !!this.form.title && this.form.title.length > 0,
                legend: !!this.form.legend && this.form.legend.length > 0,
                textContent: !!this.form.textContent && this.form.textContent.length > 0,
                keywords: !!this.form.keywords && this.form.keywords.length > 0,
                category: !!this.form.category && this.form.category > 0 && this.form.category < 4,
                linkUrl: !!this.form.linkUrl && this.form.linkUrl.length > 0,
            }
        },

        isFormValidEdit : function(){
            var ret = true
            Object.entries(this.formValidEdit).forEach(([key, value]) => {
                if(!value){
                    ret = false;
                }
            });
            return ret;
        },


    },
		created() {
            document.title= 'Videos | ' + document.title.substring(document.title.indexOf('|') + 1)
            this.$store.dispatch("auth/alterMenu", "Videos");
            this.propiedades.descricao = 'Setup the videos availability on the portal'
            this.propiedades.helperTitle = 'After conclude the video insertion remember to publish it.'
            this.propiedades.helperDesc = 'Is required an image for each platform. Mobile and desktop<br>All fields are mandatory.'
		},
mounted(){
	  this.getVideos();
      var self=this;
	  if (document.readyState === "complete" || document.readyState === "loaded") {
	 // document is already ready to go
	 self.editor();
    self.tags();
	}else
	 document.addEventListener("DOMContentLoaded", function() {
	  self.editor();
      self.tags();
	 });

     
     this.observer = new MutationObserver(function(mutations) {
            self.validTags();
        }.bind(this));
        // Setup the observer
        this.observer.observe(
            document.getElementById('sample'),
            { attributes: true, childList: true, characterData: true, subtree: true }
        );
  },
 
  methods: {
    dataFormat(dat){
        return dt.format(dt.parse(dat, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), 'DD-MM-YYYY HH:MM');
    },
    editing(vi){
        this.sending = false;
        this.form.id = vi.id;
        this.form.title= vi.title;
        this.form.textContent= vi.textContent;
        this.form.legend= vi.legend;
        this.form.showMainContents= vi.showMainContents;
        this.form.category=vi.category?.id;
        this.form.keywords= vi.keywords;
        this.form.linkUrl = vi.linkUrl;
        this.formAction = 'EDIT';
        $('#summernote').summernote('code', this.form.textContent);
        document.getElementById('sample').innerHTML = "";
        var initialToken = []
        if(this.form.keywords && this.form.keywords.length > 0){
            for (let index = 0; index < this.form.keywords.length; index++) 
                initialToken.push({value:this.form.keywords[index].value, text:this.form.keywords[index].value})
            }
        this.tokenAutocomplete = new TokenAutocomplete({
        name: 'sample',
        selector: '#sample',
        noMatchesText: 'No matching results...',
        initialTokens: initialToken,
        initialSuggestions: []
        });
        this.editor();
        window.location.href = "#ttle"
    },
    resetForm(){
        this.sending = false;
        delete this.form.id
        this.form.title= '';
        this.form.textContent= '';
        this.form.legend= '';
        this.form.showMainContents= true;
        this.form.category=1;
        this.form.keywords= [];
        this.formAction = '';
        this.linkUrl = '';
    },
    nextPage(e){
        e.preventDefault();
        this.page+=1;
        this.filtros.skip+=5;
        this.getVideos();
    },
    previousPage(e, type){
        e.preventDefault();
        this.page-=1;
        this.filtros.skip-=5;
        this.getVideos();
    },
      getVideos(){
		    this.$store.dispatch("videos/getContents", [this.filtros.order, this.filtros.limit, this.filtros.skip, this.filtros.categoria, this.filtros.search, this.filtros.typeId, this.filtros.sort, null]).then( 
          response => {
              this.videos = response.data.data; 
              this.filtros.total = response.data.totalCount;
            }, 
            error =>{
                console.log(error);
            }).catch((erro) =>{
                console.log(erro);
          });
    },

    send(){
        this.validTags();
        this.setHtml();
        this.sending = true;
        if(this.isFormValid){
            this.sending = false;
            this.$store.dispatch("videos/saveContents", this.form).then( 
                response => {
                    this.resetForm();
                    this.resetTokens();
                    this.emptyHtml();
                    document.getElementById('file-d').value = ''
                    document.getElementById('file-m').value = ''
                    document.getElementById('file-t').value = ''
                    this.getVideos();
                    this.setFeedback('Video saved successfully!', 'SUCCESS');
                }, 
                error =>{
                    this.setFeedback(error.response.data.message, 'ERROR');
                    console.log(error);
                }
            ).catch((erro) =>{
                this.setFeedback('ERROR on saving video!', 'ERROR');
                console.log(erro);
            });
            
        }else
            this.setFeedback('Verify all the required fields.', 'WARNING');
    },
    edit(){
        this.validTags();
        this.setHtml();
        this.sending = true;
        if(this.isFormValidEdit){
            this.sending = false;
            var fl1 = document.getElementById('file-d').value
            var fl2 = document.getElementById('file-m').value
            var fl3 = document.getElementById('file-t').value
            if(!fl1 || fl1 == ''){
                delete this.form.image;
            }
            if(!fl2 || fl2 == ''){
                delete this.form.imageMobile;  
            }
            if(!fl3 || fl3 == ''){
                delete this.form.thumbImage;  
            }
            this.$store.dispatch("videos/updateContents", this.form).then( 
                response => {
                    this.resetForm();
                    this.setFeedback('Videos updated successfully!', 'SUCCESS');
                    this.resetTokens();
                    this.emptyHtml();
                    document.getElementById('file-d').value = ''
                    document.getElementById('file-m').value = ''
                    document.getElementById('file-t').value = ''
                    this.getVideos();
                }, 
                error =>{
                    this.setFeedback(error.response.data.message, 'ERROR');
                    console.log(error);
                }
            ).catch((erro) =>{
                this.setFeedback('ERROR on updating videos!', 'ERROR');
                console.log(erro);
            });
        }else
            this.setFeedback('Verify all the required fields.', 'WARNING');
    },
    preChangePublish(dw, e){
        dw.publishing = e.target.checked;
    },
    changePublish(dw){
        this.$store.dispatch("videos/publisher", dw).then( 
            response => {
                this.setFeedback('Setting change successfully!', 'SUCCESS');
                this.getVideos();
            }, 
            error =>{
                this.setFeedback(error.response.data.message, 'ERROR');
                console.log(error);
            }
        ).catch((erro) =>{
            this.setFeedback('ERROR on changing setting!', 'ERROR');
            console.log(erro);
        });
    },
    deleteDW(dw){
        this.$store.dispatch("videos/delete", dw).then( 
            response => {
                this.setFeedback('Deleted successfully!', 'SUCCESS');
                this.getVideos();
            }, 
            error =>{
                this.setFeedback(error.response.data.message, 'ERROR');
                console.log(error);
            }
        ).catch((erro) =>{
            this.setFeedback('ERROR on delete!', 'ERROR');
            console.log(erro);
        });
    },
    uploadFileDesktop(e) {
        var self = this;
        var reader = new FileReader();
        var file = e.target.files[0];
        self.form.image = {};
        self.form.image.filename = file.name;
        if (e.target.files.length > 0) {
            reader.readAsDataURL(file);
            reader.onload = function (evt) {
                self.form.image.file = reader.result.split(',')[1]
            }
        }
    },

     uploadFileMobile(e) {
         var self = this;
        var reader = new FileReader();
        var file = e.target.files[0];
        self.form.imageMobile = {};
        self.form.imageMobile.filename = file.name;
        if (e.target.files.length > 0) {
            reader.readAsDataURL(file);
            reader.onload = function (evt) {
                self.form.imageMobile.file = reader.result.split(',')[1]
            }
        }
    },

    uploadFileThumb(e) {
         var self = this;
        var reader = new FileReader();
        var file = e.target.files[0];
        self.form.thumbImage = {};
        self.form.thumbImage.filename = file.name;
        if (e.target.files.length > 0) {
            reader.readAsDataURL(file);
            reader.onload = function (evt) {
                self.form.thumbImage.file = reader.result.split(',')[1]
            }
        }
    },

    
    editor(){
			$('#summernote').summernote({
                callbacks: {
                    onPaste: function (e) {
                        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    }
                },
                height: 150,
                minHeight: 260, 
                maxHeight: null,
                focus: true,
                toolbar: [
                    ['style', ['style']],
                    //['font', ['bold', 'underline', 'clear']],
                    //['color', ['color']],
                    //['para', ['ul', 'ol', 'paragraph']],
                    //['table', ['table']],
                    //['insert', ['link', 'picture', 'video']],  
                ],
                styleTags: ['p', 'h2'],            
            });
	  },
    tags(){
            this.tokenAutocomplete = new TokenAutocomplete({
            name: 'sample',
            selector: '#sample',
            noMatchesText: 'No matching results...',
            initialTokens: this.form.keywords,
            initialSuggestions: [
                {value: 'apple', text: 'Apple'},
                {value: 'banana', text: 'Banana'},
                {value: 'donut', text: 'Donut'},
                {value: 'pizza', text: 'Pizza'},
                {value: 'burger', text: 'Burger'},
                {value: 'pancake', text: 'Pancake'}
            ]
        });
        //tokenAutocomplete.debug(true);
        
    },
    resetTokens(initialToken){
          if(!initialToken)
            initialToken = []
        document.getElementById('sample').innerHTML = "";
          this.tokenAutocomplete = new TokenAutocomplete({
                name: 'sample',
                selector: '#sample',
                noMatchesText: 'No matching results...',
                initialTokens: initialToken,
                initialSuggestions: []
            });
      },
      validTags(){
          this.form.keywords = []
          if(!document.getElementById('sample-select'))
            return false;
          var tags = document.getElementById('sample-select').options;
          if(!tags || tags.length <= 0 )
            return false;
          for (let index = 0; index < tags.length; index++){
            this.form.keywords.push({value:tags[index].value});
          }
            return true;
      },
      setHtml(){
          this.form.textContent = $('#summernote').summernote('code');
      },
      emptyHtml(){
          $('#summernote').summernote('code', "");
      },

      setFeedback(m, s){
        this.propiedades.feedback.message = ''
        this.propiedades.feedback.status=''
        this.propiedades.feedback.message = m
        this.propiedades.feedback.status=s
      },
      setSort(field){
        this.filtros.sort = field;
        this.filtros.order = this.filtros.order==='desc'?'asc':'desc';
        this.filtros.skip = 0;
        this.page=1
        this.getVideos(); 
    },
  }
  
}
</script>

