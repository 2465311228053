import api from "./api";

class VideosService {
  getContents(order, limit, skip, category, search, contentType, sort, showMain) {
    var req = 'contents?';
    if(order && order.length > 0)
      req+='order='+order+'&';
    if(limit && limit !== null && limit !== '')
      req+='limit='+limit+'&';
    if(skip && skip !== null && skip !== '')
      req+='skip='+skip+'&';
    if(category && category !== null && category !== '')
      req+='category='+category+'&';
    if(search && search !== null && search !== '')
      req+='search='+search+'&';
    if(contentType && contentType !== null && contentType !== '')
      req+='contentType='+contentType+'&';
    if(sort && sort !== null && sort !== '')
      req+='sort='+sort+'&';
    if(showMain && showMain !== null && showMain !== '')
      req+='showMain='+showMain+'&';
    return api.get(req);
  }

  getContent(id){
    return api.get('contents/'+id);
  }

  saveContents(form){
    return api.post('contents', form);
  }

  updateContents(form){
    return api.put('contents/'+form.id, form);
  }

  publish(id){
    return api.post('contents/publish/'+id);
  }

  unpublish(id){
    return api.post('contents/unpublish/'+id);
  }

  delete(id){
    return api.delete('contents/'+id);
  }
}

export default new VideosService();