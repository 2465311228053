import UserService from '../services/user.service';

export const user = {
  namespaced: true,
  actions: {
    getUser({ commit }, id) {
      return UserService.getUser(id).then(
        user => {
          return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
      ).catch(e =>{
        return Promise.reject(e)
      });
    },

    getUsers({ commit }, args) {
      return UserService.getUsers(args[0], args[1]?.toString(), args[2]?.toString(), args[3]?.toString(), args[4]?.toString(), args[5]?.toString(), args[6]?.toString()).then(
        users => {
          return Promise.resolve(users);
        },
        error => {
          return Promise.reject(error);
        }
      ).catch(e =>{
        return Promise.reject(e)
      });
    },
    getRoles({ commit }) {
      return UserService.getRoles().then(
        roles => {
          return Promise.resolve(roles);
        },
        error => {
          return Promise.reject(error);
        }
      ).catch(e =>{
        return Promise.reject(e)
      });
    },

    updateUser({ commit }, args){
      return UserService.updateUser(args[0], args[1]).then(
        success => {
          return Promise.resolve("CREATED")
        }, 
        error =>{
          return Promise.resolve(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },
    createUser({ commit }, form){
      return UserService.createUser(form).then(
        success => {
          return Promise.resolve("CREATED")
        }, 
        error =>{
          return Promise.resolve(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },
  },
  mutations: {
  }
};
