import DashService from '../services/dashboard.service';

export const dash = {
  namespaced: true,
  actions: {
    getDashboard({ commit }, datas) {
      return DashService.getDashboard(datas[0], datas[1]).then(
        dash => {
          return Promise.resolve(dash);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
  }
};
