<template>
    <section class="wrapper">
        <!-- INCLUDE -->
        <div class="bar col-100">
            <div class="bar--content">
                <div class="form--calendar calendar">
                    <span>{{$t('PERIOD')}}:</span>
                    <input type="date" v-model="data1"> <span>~</span>
                    <input type="date" v-model="data2">  
                    <button class="button button--blue-gray calendar-go" type="button" @click="getAllData(data1, data2)">{{$t('GO')}}</button>           
                </div>
                <!-- <button class="button button--green export"><i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M16.44 8.9c3.6.31 5.07 2.16 5.07 6.21v.13c0 4.47-1.79 6.26-6.26 6.26H8.74c-4.47 0-6.26-1.79-6.26-6.26v-.13c0-4.02 1.45-5.87 4.99-6.2M12 2v12.88"/><path stroke-linecap="round" stroke-linejoin="round" d="M15.35 12.65L12 16l-3.35-3.35"/></svg></i> Export</button> -->
            </div>
        </div>
        <!-- INCLUDE -->
        <!-- ROW 1  mini cards ================================== -->
         <div class="row">
        <div class="modulo mini--card col-25">
            <div class="title-main">
                <h2>{{$t('MEMBERS_AVAI')}}:</h2>
                <a :href="baseUrl+'participants/exports?'+(data1?'dtStart='+data1:'')+(data2?'&dtFinish=2021-11-30':'')" class="link--card">
                    <div class="sublist">
                        <p>{{$t('DOWNLOAD_EXCEL')}}</p>
                    </div>
                </a>
            </div>
            <div class="duplo--card">
                <div class="duplo--card__content">
                    <h6>{{$t('ACTIVE')}}</h6>
                    <span class="active">{{membersActive}}</span>
                </div>
                <div class="separador"></div>
                <div class="duplo--card__content">
                    <h6>{{$t('INACTIVE')}}Inactive</h6>
                    <span class="inactive">{{membersInactive}}</span>
                </div>
            </div>
        </div>
        <div class="modulo mini--card col-25">
            <div class="title-main">
                <h2>{{$t('MEMBERS_ANALYSIS')}}:</h2>
                <router-link to="/portal/members" class="link--card">
                    <div class="sublist">
                        <p>{{$t('GO_PAGE')}}</p>
                    </div>
                </router-link>
            </div>
            <div class="single--card__content"><span>{{membersAnalyses}}</span></div>
        </div>
        </div>
        <!-- <div class="modulo mini--card col-25">
            <div class="title-main">
                <h2>Available downloads:</h2>
                <a href="" class="link--card">
                    <div class="sublist">
                        <p>Go to page</p>
                    </div>
                </a>
            </div>
            <div class="single--card__content"><span>1.453</span></div>
        </div> -->
        <!-- <div class="modulo mini--card col-25">
            <div class="title-main">
                <h2>Available masterclass:</h2>
                <a href="" class="link--card">
                    <div class="sublist">
                        <p>Go to page</p>
                    </div>
                </a>
            </div>
            <div class="single--card__content"><span>28.453</span></div>
        </div> -->
        <!-- ROW 1  mini cards ================================== -->
        <!-- ROW 2  ================================== -->
        <div class="row">
        <div class="modulo card__graphics col-60">
            <div class="title-main">
                <h2>{{$t('MEMBERS_ACCESS_MONTHLY')}}</h2>
                <a :href="baseUrl+'participants/exports?'+(data1?'dtStart='+data1:'')+(data2?'&dtFinish=2021-11-30':'')+'&groupBy=DAY'" class="link--card">
                    <div class="sublist">
                        <p>{{$t('DOWNLOAD_EXCEL')}}</p>
                    </div>
                </a>
            </div>
            <apexchart :type="gradient" :options="optionsMonthly" :series="seriesMonthly" :height="350"></apexchart>
        </div>
        <div class="modulo card__graphics col-40">
            <div class="title-main">
                <h2>{{$t('MEMBERS_ACCESS_AREA')}}</h2>
            </div>
            <apexchart :type="bar" :options="optionsTopAreas" :series="seriesTopAreas" :height="350"></apexchart>
            <!-- <div id="chart__top--access"></div> -->
        </div>
        </div>
        <!-- ROW 2  ================================== -->
        <!-- ROW 3 mini cards  ================================== -->
        <!-- <div class="modulo mini--card card--yewllow col-20">
            <div class="title-main">
                <h2>Points on hold:</h2>
                <a href="" class="link--card">
                    <div class="sublist">
                        <p>Go to page</p>
                    </div>
                </a>
            </div>
            <div class="single--card__content"><span>705.753k</span></div>
        </div>
        <div class="modulo mini--card card--green col-20">
            <div class="title-main">
                <h2>Points used:</h2>
            </div>
            <div class="single--card__content"><span>205.853k</span></div>
        </div>
        <div class="modulo mini--card card--red col-20">
            <div class="title-main">
                <h2>Points expired:</h2>
            </div>
            <div class="single--card__content"><span>103.853k</span></div>
        </div>
        <div class="modulo mini--card col-20">
            <div class="title-main">
                <h2>Points distributed:</h2>
            </div>
            <div class="single--card__content"><span>65.753k</span></div>
        </div>
        <div class="modulo mini--card col-20">
            <div class="title-main">
                <h2>Total:</h2>
            </div>
            <div class="percent--status">
                <p>Dist: <span>30%</span></p>
                <p>Hold: <span>25%</span></p>
                <p>Used: <span>100%</span></p>
            </div>
        </div> -->
        <!-- ROW 3 mini cards  ================================== -->
        <!-- <div class="modulo card__graphics col-40">
            <div class="title-main">
                <h2>Download Types</h2>
            </div>
            <div id="chart__class--download"></div>
        </div>
        <div class="modulo card__graphics col-60">
            <div class="title-main">
                <h2>Masterclass audience</h2>
                <a href="" class="link--card">
                    <div class="sublist">
                        <p>Download Excel</p>
                    </div>
                </a>
            </div>
            <div id="chart__class--audience"></div>
        </div> -->
        <!-- ROW 3 cards  ================================== -->
        <!-- ROW 4   ================================== -->
        <!-- <h2 class="subtitle col-100">Sales</h2>
        <div class="card__graphics obs--voucher col-30">
            <div class="modulo total-voucher">
                <div class="title-main">
                    <h2>Vouchers from OBS</h2>
                    <a href="" class="link--card">
                        <div class="sublist">
                            <p>Download Excel</p>
                        </div>
                    </a>
                </div>
                <div id="chart__voucher--obs"></div>
            </div>
            <div class="modulo mini--card">
                <div class="title-main">
                    <h2>Members with voucher set:</h2>
                    <a href="" class="link--card">
                        <div class="sublist">
                            <p>Download Excel</p>
                        </div>
                    </a>
                </div>
                <div class="single--card__content"><span>65.753k</span></div>
            </div>
        </div>
        <div class="modulo card__graphics col-70">
            <div class="title-main">
                <h2>OBS - Poins distribuition Monthly</h2>
            </div>
            <div id="chart__voucher--obsMonthly"></div>
        </div> -->
    </section>
</template>


<script>
//import "@/assets/logged/app.min.css";
import dt from 'date-and-time';

export default {
    props: {
        search: String,
        propiedades: Object
    },
    data(){
        return{
            baseUrl: process.env.VUE_APP_API_URL,
            membersActive: '',
            membersInactive: '',
            membersAnalyses: '',
            topAreasIf: false,
            data1: '',
            data2: '',
            optionsTopAreas : {
                chart: {
                    type: 'bar',
                    fontFamily: 'Gilroy-Semibold, Helvetica, Arial, sans-serif',
                        style: { 
                            colors: ['#a50034']
                        },
                    toolbar: {
                        show: false,
                        },
                },
                grid: {
                    show: true,
                    borderColor: '#eaeef1',
                    position: 'back'
                },              
                yaxis:{
                    show: true,
                    labels: {
                        show: false,
                        align: 'right',
                        minWidth: 0,
                        maxWidth: 160,
                        style: {
                            colors: ['#90a4ae'],
                            fontSize: '10px',
                            cssClass: 'apexcharts-yaxis-label',
                        },
                    },
                },
                xaxis: {
                    //PRECISA UPDATE
                    categories: [],
                    labels: {
                        style: {
                            colors: ['#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388',],
                            fontSize: '12px',
                            cssClass: 'apexcharts-xaxis-label',      
                        },
                    },
                    axisBorder: {
                        show: true,
                        color: '#fbfdfe',
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: false

                    },   
                },
                fill: {
                    colors: ['#0e7cad']
                },
            },
            seriesTopAreas: [{
                name: 'Access',
                //PRECISA ALTERACAO
                data: []
            }],

            //MEMBER ACCESS MONTHLY
            optionsMonthly : {
                chart: {
                //height: 280,
                type: "area",
                fontFamily: 'Gilroy-Semibold, Helvetica, Arial, sans-serif',
                toolbar: {
                    show: false,
                },
                
                },
                dataLabels: {
                enabled: false,
                style: {
                    colors: ['#2E294E']
                }
                },

            
                colors: ["#1b9dd7"],
                stroke: {
                width: [3, 1],
                curve: 'smooth'
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100],
                    }
                },

                grid: {
                    show: true,
                    borderColor: '#eaeef1',
                    position: 'back'
                },

                yaxis:{
                    show: true,
                    labels: {
                        show: false,
                    }
                    
                },
                
                xaxis: {
                    type: 'monthly',
                    //POPULAR
                    categories: [],
                    labels: {
                        style: {
                            colors: ['#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388', '#677388',],
                            fontSize: '12px',
                            cssClass: 'apexcharts-xaxis-label',      
                        },
                    },
                    
                    axisBorder: {
                        show: true,
                        color: '#fbfdfe',
                        height: 1,
                        width: '100%',
                        offsetX: 0,
                        offsetY: 0
                    },
                    axisTicks: {
                        show: false

                    },     
                }
                
            },
            seriesMonthly: [{
                name: "Access",
                //POPULAR
                data: []
            }],
        }
    },
    created() {
        document.title= 'Dashboard | ' + document.title.substring(document.title.lastIndexOf('|') + 1)
        this.$store.dispatch("auth/alterMenu", "Dashboard");
        this.propiedades.descricao = 'Summary of portal information.'
        this.propiedades.helperTitle = 'Here an overview of the portal data.'
        this.propiedades.helperDesc = ''
        this.getAllData();
    },

    methods : {
        getAllData(dt1, dt2){
            this.$store.dispatch("dash/getDashboard", [dt1, dt2]).then((resp) =>{
            //this.topAreasIf = false;
            //this.monthAreasIf = false;
            this.optionsMonthly.xaxis.categories = []
            this.membersActive = resp.data.participants.find(function(obj){return obj.status == 'APPROVED'})?.valor;
            this.membersAnalyses = resp.data.participants.find(function(obj){return obj.status == 'ANALYSIS'})?.valor;
            this.membersInactive = resp.data.participants.find(function(obj){return obj.status == 'REJECTED'})?.valor;
            this.membersActive = this.membersActive?this.membersActive:0;
            this.membersAnalyses = this.membersAnalyses?this.membersAnalyses:0;
            this.membersInactive = this.membersInactive?this.membersInactive:0;
            var seriesTop = resp.data.memberAccessTop.sort(function(a, b){
                if (a.valor < b.valor)
                    return 1;
                if (a.valor > b.valor)
                    return -1;
                return 0;
            }).slice(0, 3);
            this.optionsTopAreas = {
                xaxis: {
                    categories: seriesTop.map(a => a.area) 
                }
            }
            //this.optionsTopAreas.xaxis.categories = seriesTop.map(a => a.area);
            this.seriesTopAreas[0].data = seriesTop.map(a => a.valor);
            //this.seriesTopAreas = seriesTop.map(a => a.valor);

            var self = this;
            var aux = resp.data.memberAccessMonthly;
            var graph = aux.slice(aux.length-12>=0?aux.length-12:0, aux.length);
            this.optionsMonthly = {
                xaxis: {
                    categories: graph.map(a => a.month) 
                }
            }
            this.seriesMonthly[0].data = graph.map(a => a.valor);
            //this.topAreasIf = true;
            //this.monthAreasIf = true;
        });
        },
        getMonth(month){
            switch(month){
                case 1:return "Jan";
                case 2:return "Feb";
                case 3:return "Mar";
                case 4:return "Apr";
                case 5:return "May";
                case 6:return "Jun";
                case 7:return "Jul";
                case 8:return "Aug";
                case 9:return "Sep";
                case 10:return "Out";
                case 11:return "Nov";
                case 12:return "Dez";
                default:return "Unk";
            }
        }
    }
};
</script>
