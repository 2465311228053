import axios from "axios";

console.log(process.env.VUE_APP_VERSION);
console.log(process.env.VUE_APP_API_URL);

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;