import axios from "./api";

class ArchitectService {

  getArquitects(status, skip, limit, sort, order, search) {
    var params = '?';
    if(status && status !== null && status !== '')
      params+='status='+status+'&'
    if(skip && skip !== null && skip !== '')
      params+='skip='+skip+'&'
    if(limit && limit !== null && limit !== '')
      params+='limit='+limit+'&'
    if(sort && sort !== null && sort !== '')
      params+='sort='+sort+'&'
    if(order && order !== null && order !== '')
      params+='order='+order+'&'
    if(search && search !== null && search !== '')
      params+='search='+search+'&'
    return axios.get('/participants'+params);
  }

  getArquitect(id) {
    return axios.get('/participants/'+id);
  }

  deleteArquitect(id) {
    return axios.delete('/participants/'+id);
  }

  approveArquitect(id, reason) {
    return axios.post('/participants/approve/'+id,{
      reason: reason
    });
  }

  rejectArquitect(id, reason) {
    return axios.post('/participants/reject/'+id, {
      reason: reason
    });
  }

  updatePart(id, body){
    return axios.put("participants/"+id, body);
  }
}

export default new ArchitectService();
