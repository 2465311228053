<template>
        <!-- INCLUDE -->
        <aside>
             <div class="assite--container">
                <div class="logo">
                     <img src="@/assets/admin/img/logo-lg.svg" alt="LG" title="LG">
                </div> 
                <button class="btn--menu__mobile" @click="propiedades.mobileShow = !propiedades.mobileShow" v-bind:class="{ active: propiedades.mobileShow }">                         
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <nav>
                <router-link class="link-side" v-bind:class="{'active':url.indexOf('portal/dashboard')>= 0}" to="/portal/dashboard">
                    <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" d="M15.5 18.5c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2s-2 .9-2 2v9a2 2 0 002 2zM8.5 18.5c1.1 0 2-.9 2-2V13c0-1.1-.9-2-2-2s-2 .9-2 2v3.5a2 2 0 002 2z"/></svg></i>
                    <span>{{$t('DASH')}}</span>
                </router-link>
                 <legend>{{$t('CONTENTS')}}</legend>               
                 <router-link class="link-side" v-bind:class="{'active':url.indexOf('videos')>= 0}" to="/portal/videos">
                    <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M9.1 12v-1.48c0-1.91 1.35-2.68 3-1.73l1.28.74 1.28.74c1.65.95 1.65 2.51 0 3.46l-1.28.74-1.28.74c-1.65.95-3 .17-3-1.73V12z"/></svg></i>
                    <span>{{$t('VIDEOS')}}</span>
                </router-link>
                <router-link class="link-side" v-bind:class="{'active':url.indexOf('downloads')>= 0}" to="/portal/downloads">
                    <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" d="M9 11.51l3 3 3-3M12 14.51v-8M6 16.51c3.89 1.3 8.11 1.3 12 0"/></svg></i>
                    <span>{{$t('DOWNLOADS')}}</span>
                </router-link>
                <router-link class="link-side" v-bind:class="{'active':url.indexOf('news') >= 0}" to="/portal/news">
                    <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5"/><path stroke-linecap="round" stroke-linejoin="round" d="M22 10h-4c-3 0-4-1-4-4V2l8 8zM7 13h6M7 17h4"/></svg></i>
                    <span>{{$t('NEWS')}}</span>
                </router-link> 
                 <router-link class="link-side" v-bind:class="{'active':url.indexOf('members')>= 0}" to="/portal/members">
                    <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M18.14 21.62c-.88.26-1.92.38-3.14.38H9c-1.22 0-2.26-.12-3.14-.38.22-2.6 2.89-4.65 6.14-4.65 3.25 0 5.92 2.05 6.14 4.65z"/><path stroke-linecap="round" stroke-linejoin="round" d="M15 2H9C4 2 2 4 2 9v6c0 3.78 1.14 5.85 3.86 6.62.22-2.6 2.89-4.65 6.14-4.65 3.25 0 5.92 2.05 6.14 4.65C20.86 20.85 22 18.78 22 15V9c0-5-2-7-7-7zm-3 12.17c-1.98 0-3.58-1.61-3.58-3.59C8.42 8.6 10.02 7 12 7s3.58 1.6 3.58 3.58-1.6 3.59-3.58 3.59z"/><path stroke-linecap="round" stroke-linejoin="round" d="M15.58 10.58c0 1.98-1.6 3.59-3.58 3.59s-3.58-1.61-3.58-3.59C8.42 8.6 10.02 7 12 7s3.58 1.6 3.58 3.58z"/></svg></i>              
                        <span>{{$t('MEMBERS')}}</span> <span v-if="totalAnalise>0" class="alert">{{totalAnalise}}</span>
                </router-link>
                <span v-if="isAdmin" class="separador"></span> 
                 <legend>{{$t('NAV_CAMP')}}</legend>
                 <!-- <router-link class="link-side" v-bind:class="{'active':url.indexOf('campaigns')>= 0}" to="/portal/campaigns">
                     <i> <svg viewBox="0 0 24 24"><path d="M16.7 18.98H7.30002C6.88002 18.98 6.41002 18.65 6.27002 18.25L2.13002 6.66999C1.54002 5.00999 2.23002 4.49999 3.65002 5.51999L7.55002 8.30999C8.20002 8.75999 8.94002 8.52999 9.22002 7.79999L10.98 3.10999C11.54 1.60999 12.47 1.60999 13.03 3.10999L14.79 7.79999C15.07 8.52999 15.81 8.75999 16.45 8.30999L20.11 5.69999C21.67 4.57999 22.42 5.14999 21.78 6.95999L17.74 18.27C17.59 18.65 17.12 18.98 16.7 18.98Z" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.5 22H17.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.5 14H14.5" stroke-linecap="round" stroke-linejoin="round"/></svg></i>              
                         <span>{{$t('NAV_CAMP')}}</span>               
                 </router-link> -->
                 <router-link class="link-side" v-bind:class="{'active':url.indexOf('registrations')>= 0}" to="/portal/registrations">
                    <i><svg viewBox="0 0 24 24"><path d="M12.37 8.87988H17.62" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.38 8.87988L7.13 9.62988L9.38 7.37988" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.37 15.8799H17.62" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.38 15.8799L7.13 16.6299L9.38 14.3799" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke-linecap="round" stroke-linejoin="round"/></svg></i>              
                        <span>{{$t('NAV_INV')}}</span> <span v-if="totalAnaliseInvoice>0" class="alert">{{totalAnaliseInvoice}}</span>
                </router-link>
                 <!-- <router-link class="link-side" v-bind:class="{'active':url.indexOf('products')>= 0}" to="/portal/products">
                     <i><svg fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M17.01 12.73a1.07 1.07 0 100-2.14 1.07 1.07 0 000 2.14z"/><path stroke-linecap="round" stroke-linejoin="round" d="M20 6v1.79c-.25-.03-.54-.05-.85-.05h-4.28c-2.14 0-2.85.71-2.85 2.85v5.11H6c-3.2 0-4-.8-4-4V6c0-3.2.8-4 4-4h10c3.2 0 4 .8 4 4zM9 15.7V20M2 11.9h10M5.95 20H12"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M17.01 12.73a1.07 1.07 0 100-2.14 1.07 1.07 0 000 2.14z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M20 7.79c-.25-.03-.54-.05-.85-.05h-4.28c-2.14 0-2.85.71-2.85 2.85v8.56c0 2.14.71 2.85 2.85 2.85h4.28c2.14 0 2.85-.71 2.85-2.85v-8.56c0-1.83-.52-2.61-2-2.8zm-2.99 2.8a1.071 1.071 0 010 2.14 1.071 1.071 0 010-2.14zm0 8.56c-1.18 0-2.14-.96-2.14-2.14a2.142 2.142 0 013.54-1.62c.45.4.74.98.74 1.62 0 1.18-.96 2.14-2.14 2.14z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M19.15 17.01c0 1.18-.96 2.14-2.14 2.14-1.18 0-2.14-.96-2.14-2.14a2.142 2.142 0 013.54-1.62c.45.4.74.98.74 1.62zM17.01 12.73a1.07 1.07 0 100-2.14 1.07 1.07 0 000 2.14z"/></svg></i>              
                         <span>{{$t('NAV_PROD')}}</span>               
                 </router-link>                -->
                 <span v-if="isAdmin" class="separador"></span> 
                  <legend v-if="isAdmin">{{$t('SETTINGS')}}</legend>
                <router-link v-if="isAdmin" class="link-side" v-bind:class="{'active':url.indexOf('settings')>= 0}" to="/portal/settings">
                    <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M12 12a5 5 0 100-10 5 5 0 000 10zM20.59 22c0-3.87-3.85-7-8.59-7s-8.59 3.13-8.59 7"/></svg></i>
                    <span>{{$t('USERS')}}</span>
                    </router-link>
         
                <!-- <router-link class="link-side icon-user" v-bind:class="{'active':url.indexOf('profile')>= 0}" to="/portal/profile">My Profile</router-link> -->
               
            </nav>
        </aside>
        <!-- INCLUDE -->
</template>

<script>

export default {
    props:{
        propiedades: Object
    },
    data(){
        return{
            totalAnalise: 0,
            totalAnaliseInvoice: 0,
            url: window.location.pathname
        }
    },
    computed: {
        isAdmin : function() {
            return this.$store.state.auth.user?.role?.id === 1;
        },

        loggedIn() {
          return this.$store.state.auth.status.loggedIn;
        },
    },
    created(){
        this.$store.dispatch("arch/getArquitects", ["ANALYSIS", 0, 1]).then((resp) =>{
            this.totalAnalise = resp.data.totalCount;
        });
        setInterval(() => {
            if(this.loggedIn)
                this.$store.dispatch("arch/getArquitects", ["ANALYSIS", 0, 1]).then((resp) =>{
                    this.totalAnalise = resp.data.totalCount;
                });
        }, 60000);
        this.$store.dispatch("invoice/getInvoicesProducts", [1, 0, 1, null, null, null]).then((resp) => {
            this.totalAnaliseInvoice = resp.data.count;
        });
        setInterval(() => {
            if(this.loggedIn)
            this.$store.dispatch("invoice/getInvoicesProducts", [1, 0, 1, null, null, null]).then((resp) => {
                this.totalAnaliseInvoice = resp.data.count;
            });
        }, 60000);
    },
    watch:{
        $route (to, from){
            this.url = window.location.pathname
        }
    }, 
    methods:{
    }
}

</script>
