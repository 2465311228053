import axios from "./api";

class ArchitectService {

  getNews(limit, skip, publish, keys, sort, order, search, exclude) {
    var params = '?';
    if(limit && limit !== null && limit !== '')
      params+='limit='+limit+'&'
    if(skip && skip !== null && skip !== '')
      params+='skip='+skip+'&'
    if(publish && publish !== null && publish !== '')
      params+='publish='+publish+'&'
    if(keys && keys !== null && keys !== '')
      params+='keys='+keys+'&';
    if(sort && sort !== null && sort !== '')
      params+='sort='+sort+'&'
    if(order && order !== null && order !== '')
      params+='order='+order+'&'
    if(search && search !== null && search !== '')
    params+='search='+search+'&';
    if(exclude && exclude !== null && exclude !== '')
      params+='exclude='+exclude+'&'
    return axios.get('/newsletter'+params);
  }
  deleteNews(id){
    return axios.delete('/newsletter/'+id);
  }
  
  publish(id){
    return axios.post('/newsletter/publish/'+id);
  }

  unpublish(id){
    return axios.post('/newsletter/unpublish/'+id);
  }

  update(id, form){
    return axios.put('/newsletter/'+id, form);
  }

  create(form){
    return axios.post('/newsletter', form);
  }

  getNewsId(id){
    return axios.get('/newsletter/'+id);
  }
}

export default new ArchitectService();
