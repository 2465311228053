<template>
    <Modal v-if="isVisibility" @toggle-modal="toggleModal">      
        <template v-slot:header--modal>
            <h2>{{$store.state.auth.menu +' '+$t('PROF')}}</h2>
            <p>{{$t('MEMBERS_DESC')}}</p>
        </template>

        <template v-slot:container--modal>
            <form>
                <div class="card__modal grid-form-100">
                   <div class="form--input grid-form-60">
                        <label for="nome">{{$t('NAME')}}:</label>            
                        <input :disabled="!isEdit" type="text" name="nome"  v-model="arch.user.name">
                    </div>
                    <div class="form--input grid-form-40">
                        <label for="nome">{{$t('CPF')}}:</label>            
                        <input disabled type="text" name="nome"  v-model="arch.cpf">
                    </div>
                    <div class="form--input grid-form-60">
                        <label for="nome">{{$t('PROFESSION_NUMBER')}}:</label>            
                        <input :disabled="!isEdit" type="text" name="nome"  v-model="arch.professionalDoc">
                    </div>
                    <div class="form--input grid-form-40">
                        <label for="nome">{{$t('INSTA_PROFESSIONAL')}}:</label>            
                        <input :disabled="!isEdit" type="text" name="nome"  v-model="arch.profession">
                    </div>
                    <div class="form--input grid-form-60">
                        <label for="nome">{{$t('LG_MAGENTA_KNOW')}}</label>            
                        <input :disabled="!isEdit" type="text" name="nome"  v-model="arch.howDidYouGet">
                    </div>
                    <div class="form--input grid-form-40">
                        <label for="nome">{{$t('INSTA')}}</label>            
                        <input :disabled="!isEdit" type="text" name="nome"  v-model="arch.instagram">
                    </div>
                    <div class="clear"></div>
                </div>
                
                <div class="card__modal grid-form-100" v-show="(action !== 'REJECTED' && !isEdit) || (isEdit && action === 'REJECTED')">
                    <div class="form--input reason">
                        <label for="reason" class="required">{{$t('REASON')}}</label>
                        <textarea type="text" id="reason" :class="[!arch.reason.trim() && reasonFg? 'error':'' ]"  v-model="arch.reason" name="reason"></textarea>
                        <span v-show="!arch.reason.trim() && reasonFg" class="alert">{{$t('FILL_REASON')}}</span>       
                    </div>
                </div>
                <div class="card__modal modal__btns grid-form-100">
                    <button @click="cancelEdit" v-if="isEdit" type="button" class="button button--cancel">{{$t('CANCEL')}}</button>
                    <button @click="update" v-if="action !== 'ANALYSIS'" type="button" class="button">{{isSave?$t('SAVE'):$t('EDIT')}}</button>
                    <!-- <button @click="update" v-if="action !== 'ANALYSIS'" type="button" class="button">{{isEdit?'Save':'Edit'}}</button> -->
                    <button type="button" v-on:click="reject(arch.id)" v-if="action !== 'REJECTED' && !isEdit" class="button button--red">{{$t('REJECT')}}</button>
                    <button type="button" v-on:click="approve(arch.id)" v-if="action !== 'APPROVED' && !isEdit" class="button button--green">{{$t('APPROVE')}}</button>
                </div>
            </form>
        </template>
    </Modal>
        <section class="modulo list__grid architect--on-approved col-100">
            <header class="header-list">
                <div class="header-list--content">
                    <h4>{{$t('APPROVE_TO')}}</h4>
                    <span class="amount">{{totalToApproved +' '+$t('NEWS')}}</span> 
                </div>
                 <div class="content__form">
                    <input id="srch" v-model="sort.analyse.search" class="search" type="search" :placeholder="$t('SEARCH_NAME')">
                </div>      
            </header>
             <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                <thead>
                   <tr>
                        <th @click="setSortWait('us.name')"  class="row__head"><span>{{$t('USER')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortWait('us.email')" class="row__head"><span>{{$t('EMAIL')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortWait('pa.createdAt')" class="row__head"><span>{{$t('JOIN')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>   
                        <th class="row__head">{{$t('ACTIONS')}}</th>            
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(arc, index) in archsWait" :key="index">
                        <td class="itens__description">{{arc.user.name}}</td>
                        <td class="itens__description">{{arc.user.email}}</td>
                        <td class="itens__description">{{dataFormat(arc.createdAt)}}</td>             
                        <td><a href="#modal-basic" class="button button--gray-stroke" @click="visual(arc, $event, 'ANALYSIS')">{{$t('APPROVE_REJECT')}}</a></td>
                    </tr>
                </tbody>
            </table>
              <div class="pagination">
                <span>{{(5*page.toAprove>=totalToApproved?totalToApproved:(page.toAprove<=1?page.toAprove:(page.toAprove*5)-5))+'-'+(5*page.toAprove>=totalToApproved?totalToApproved:5*page.toAprove)+' '+$t('OF')+' '+totalToApproved+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event, 'toAprove')" :disabled="page.toAprove <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event, 'toAprove')" href="#" v-show="page.toAprove > 1" class="btn--pagination">{{page.toAprove-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page.toAprove}}</a></li>
                        <li><a @click="nextPage($event, 'toAprove')" href="#" v-show="page.toAprove*5 < totalToApproved" class="btn--pagination">{{page.toAprove+1}}</a> </li>
                    </ul>
                    <button @click="nextPage($event, 'toAprove')" :disabled="page.toAprove*5 >= totalToApproved" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
            <!-- <a href="#" @click="moreWaitings($event)" class="more-items">{{this.skips.toAprove <= this.archsWait.length?'Load more items':'End'}}</a> -->
        </section>


        <section class="modulo list__grid archives architect--approved col-100">
            <header class="header-list">  
                <div class="header-list--content">
                    <h4>{{$t('MEMBERS')}}</h4>
                    <span class="amount">{{totalApproved +' '+$t('USERS')}}</span>                               
                </div>  
                <div class="content__form">
                    <input id="srch" v-model="sort.other.search" class="search" type="search" :placeholder="$t('SEARCH_NAME')">
                </div>                   
            </header>
            <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table v-if="archsApproved && archsApproved.length > 0" class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                
                <thead>
                    <tr>
                        <th @click="setSortOther('us.name')"  class="row__head"><span>{{$t('NAME')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('us.email')" class="row__head"><span>{{$t('EMAIL')}} <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('pa.createdAt')" class="row__head"><span>{{$t('JOIN')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSortOther('st.name')"  class="row__head"><span>{{$t('STATUS')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th class="row__head">{{$t('ACTIONS')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(arc, index) in archsApproved" :key="index">
                        <td class="itens__description">{{arc.user.name}}</td>
                        <td class="itens__description">{{arc.user.email}}</td>
                        <td class="itens__description">{{dataFormat(arc.createdAt)}}</td>
                        <td>
                            <span class="status__members" :class="[arc.status.name == 'APPROVED'?'active':'rejected']">{{arc.status.name == 'APPROVED'?$t('ACTIONS'):$t('REJECT')}}</span>
                            <!-- <span class="status__members rejected">Rejected</span> -->
                            <!-- <span class="status__members deactivate">Deactivate</span> -->
                        </td>
                        <td><button @click="visual(arc, $event, arc.status.name == 'APPROVED'?'APPROVED':'REJECTED')" class="button button--gray-stroke">{{$t('PROFILE_SHOW')}}</button> </td>
                        <!-- <td><a href="#modal-basic" class="button button--gray-stroke" @click="visual(arc, $event, 'APPROVED')">show profile</a></td> -->
                    </tr>
                </tbody>
            </table>
            <div class="pagination">
                <span>{{(5*page.approve>=totalApproved?totalApproved:(page.approve<=1?page.approve:(page.approve*5)-5))+'-'+(5*page.approve>=totalApproved?totalApproved:5*page.approve)+' '+$t('OF')+' '+totalApproved+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event, 'approve')" :disabled="page.approve <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event, 'approve')" v-show="page.approve > 1" class="btn--pagination">{{page.approve-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page.approve}}</a></li>
                        <li><a @click="nextPage($event, 'approve')" v-show="page.approve*5 < totalApproved" class="btn--pagination">{{page.approve+1}}</a> </li>
                    </ul>     
                    <button @click="nextPage($event, 'approve')" :disabled="page.approve*5 >= totalApproved" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
        </section>
</template>


<script>
import dt from 'date-and-time';
import Modal from '@/components/Modal.vue';
export default {
    
    name: "Login",
    components: {
        Modal,
    },
  props: {
      search: String,
      propiedades: Object
  },
  data() {
    return {
        loading:{
            approved: {
                show: false,
                msg: '',
                loading: ''
            },
            rejected: {
                show: false,
                msg: '',
                loading: ''
            },
            toAprove: {
                show: false,
                msg: '',
                loading: ''
            }
        },
        isEdit:false,
        archsWait:[],
        archsApproved:[],
        arch:{},
        view:false,
        page:{
            toAprove:1,
            approve:1,
            rejected:1
        },
        sort:{
            analyse:{
                field:'pa.createdAt',
                order:'desc',
                search: ''
            },
            other:{
                field:'pa.createdAt',
                order:'desc',
                search: ''
            }
        },
        reasonFg: false,
        reason: "",
        totalRejected:0,
        totalApproved:0,
        totalToApproved:0,
        action:'',
        isVisibility: false
    };
  },
  watch: {
    'sort.analyse.search' : function(val){
        this.page.toAprove=1,
        this.loadtoAprove();
    },
    'sort.other.search' : function(val){
        this.page.approve=1,
        this.loadapprove();
    }
  },
  computed: {
    loggedIn() {
      return this.$store.architects;
    }
  },
  mounted(){
      this.view = false;
  },
  created() {
    document.title= 'Members | ' + document.title.substring(document.title.lastIndexOf('|') + 1)
    this.propiedades.descricao = 'Below, the persons that requires approval to login on the portal.<br/>Also, is possible to manage the members status information.'
    this.propiedades.helperTitle = 'The members can have 3 status.'
    this.propiedades.helperDesc = '1 - On Analysis: The initial status, where the member has limitaded access to the portal'+
    '<br/>2 - Approved'+'<br/>3 - Rejects<br/>To have full access the member have to be approved, in case of rejection  is necessary to fill the reason to.'
    var self = this;
    this.loadapprove()
    // this.loading.toAprove.show = true;
    // self.loading.toAprove.msg = "Loading"
    // this.loading.toAprove.loading = setInterval(() => {
    //     self.loading.toAprove.msg+="."
    //     if(self.loading.toAprove.msg.length>20)
    //         self.loading.toAprove.msg = 'Loading'
    // }, 500);
    this.loadtoAprove();
    this.$store.dispatch("auth/alterMenu", "Members");
  },
  
  
  methods: {
    dataFormat(dat){
        return dt.format(dt.parse(dat, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), 'DD-MM-YYYY HH:MM');
    },
    nextPage(e, type){
        e.preventDefault();
        this.page[type]+=1;
        this['load'+type]();
    },
    previousPage(e, type){
        e.preventDefault();
        this.page[type]-=1;
        this['load'+type]();
    },
    loadapprove(){
        this.$store.dispatch("arch/getArquitects", ["NOT_ANALYSIS", (this.page.approve-1)*5, 5, this.sort.other.field, this.sort.other.order, this.sort.other.search]).then((resp) =>{
            this.archsApproved = resp.data.data;
            this.totalApproved = resp.data.totalCount;
        });
    },
    loadtoAprove(){
        this.$store.dispatch("arch/getArquitects", ["ANALYSIS", (this.page.toAprove-1)*5, 5, this.sort.analyse.field, this.sort.analyse.order, this.sort.analyse.search]).then((resp) =>{
            this.archsWait = resp.data.data;
            this.totalToApproved = resp.data.totalCount;
        });
    },
	getArchitects(){
        this.loadapprove();
        this.loadtoAprove();
    },
    approve(id){
        this.$store.dispatch("arch/approve", [id, this.arch.reason]).then((resp) =>{
            this.reasonFg = false;
            this.action = 'APPROVED'
            this.arch = resp.data;
            if(!this.arch.reason)
                this.arch.reason = '';
            this.propiedades.feedback.status = 'SUCCESS'
            this.propiedades.feedback.message = 'Arquiteto aprovado com sucesso!'
            this.getArchitects();
        }).catch(error =>{
            this.propiedades.feedback.status = 'DANGER'
            this.propiedades.feedback.message = 'Falha na aprovação do arquiteto!'
        });
    },
    reject(id){
        if(!this.arch.reason.trim()){
            this.reasonFg=true;
            return;
        }
        this.$store.dispatch("arch/reject", [id, this.arch.reason]).then((resp) =>{
            this.reasonFg = false;
            this.action = 'REJECTED'
            this.arch = resp.data;
            if(!this.arch.reason)
                this.arch.reason = '';
            this.propiedades.feedback.status = 'SUCCESS'
            this.propiedades.feedback.message = 'Arquiteto rejeitado com sucesso!'
            this.getArchitects();
        }).catch(error =>{
            this.propiedades.feedback.status = 'DANGER'
            this.propiedades.feedback.message = 'Falha na rejeição do arquiteto!'
        });
    },
    cancelEdit(){
        this.isEdit = false;
        this.$store.dispatch("arch/getArquitect", this.arch.id).then((resp) =>{
            this.arch = resp.data;
            if(!this.arch.reason)
                this.arch.reason = '';
        });
    },
    update(){
        if(!this.isEdit){
            this.isEdit = true;
            return;
        }
        var form = {}
        form.id = this.arch.id;
        form.howDidYouGet = this.arch.howDidYouGet;
        form.name = this.arch.user.name;
        form.professionalDoc = this.arch.professionalDoc;
        form.profession = this.arch.profession;
        form.reason = this.arch.reason;
        form.instagram = this.arch.instagram;
        var self = this;
        this.$store.dispatch("arch/updatePart", [form.id, JSON.stringify(form)]).then(
            response => {
                if(response.status === 200){
                    this.propiedades.feedback.status = "SUCCESS"
                    this.propiedades.feedback.message = "Atualizado com sucesso!"
                    this.arch = response.data;
                    if(!this.arch.reason)
                        this.arch.reason = '';
                }
            }, 
            error =>{
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = error.response.data.message  ;
            }
        ).catch((erro) =>{
            this.propiedades.feedback.status = 'DANGER'
            if(erro.hasOwnProperty('response'))
                if(erro.response.hasOwnProperty('data'))
                    this.propiedades.feedback.message = error.response.data.message  ;
                else
                    this.propiedades.feedback.message = erro.response.message;
            else     
                this.propiedades.feedback.message = "Erro ao atualizar o arquiteto!"
        }).finally(function (){
            self.isEdit = false;
            self.getArchitects();
        });
    },
    visual(obj, event, act){
        //event.preventDefault();
        this.isEdit = false;
        this.action = act;
        this.isVisibility = true;
        this.arch = obj;
        if(!this.arch.reason)
            this.arch.reason = '';
        
    },

    deleta(id, event){
        event.preventDefault();
        this.$store.dispatch("arch/deleteArquitect", id).then((resp) =>{
            this.getArchitects();
            this.arch = resp.data;
            this.propiedades.feedback.status = 'SUCCESS'
            this.propiedades.feedback.message = 'Excluido com sucesso!'
        }).catch(error =>{
            this.propiedades.feedback.status = 'DANGER'
            this.propiedades.feedback.message = 'Falha na exclusão!'
        });
    },
    setSortWait(field){
        this.sort.analyse.field = field;
        this.sort.analyse.order = this.sort.analyse.order==='desc'?'asc':'desc';
        this.page.toAprove=1
        this.loadtoAprove(); 
    },
    setSortOther(field){
        this.sort.other.field = field;
        this.sort.other.order = this.sort.other.order==='desc'?'asc':'desc';
        this.page.approve=1
        this.loadapprove(); 
    },
    toggleModal(e, obj, action){
        this.isVisibility = false;
    }
  }
};
</script>