import DownloadsService from '../services/downloads.service';

export const downloads = {
  namespaced: true,
  actions: {
    getContents({ commit }, params) {
      return DownloadsService.getContents(params[0]?.toString(), params[1]?.toString(), params[2]?.toString(), params[3]?.toString(), params[4]?.toString(), params[5]?.toString(), params[6]?.toString(), params[7]?.toString());
    },

    getContent({ commit }, id){
      return DownloadsService.getContent(id);
    }

    ,saveContents({ commit }, form){
      return DownloadsService.saveContents(form);
    }

    ,updateContents({ commit }, form){
      return DownloadsService.updateContents(form);
    }

    ,publisher({ commit }, dw){
      if(!dw.publishing)
        return DownloadsService.unpublish(dw.id);
      else
        return DownloadsService.publish(dw.id);
    },

    delete({ commit }, dw){
      return DownloadsService.delete(dw.id);
    }
  },
  mutations: {}
};
