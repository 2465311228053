<template>
  <section class="wrapper">
            
        <div class="row content-adjusted">
             <div class="modulo profile__card col-22">
                 <div class="profile__card--header">
                     <div class="profile--avatar">{{main.name.substring(0, 1)+main.name.substring(main.name.lastIndexOf(' ')+1, main.name.lastIndexOf(' ')+2)}}</div>
                     <h3>{{main.name}}</h3>
                     <p>{{main.role.name}}</p>
                 </div>
                 <nav class="profile--nav">
                    <a @click.prevent="action = 'PROFILE'" class="profile--nav--link">
                        <i>
                            <svg viewBox="0 0 24 24">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 12a5 5 0 100-10 5 5 0 000 10z"/>
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M19.21 15.74l-3.54 3.54c-.14.14-.27.4-.3.59l-.19 1.35c-.07.49.27.83.76.76l1.35-.19c.19-.03.46-.16.59-.3l3.54-3.54c.61-.61.9-1.32 0-2.22-.89-.89-1.6-.6-2.21.01zM18.7 16.25c.3 1.08 1.14 1.92 2.22 2.22"/>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3.41 22c0-3.87 3.85-7 8.59-7 1.04 0 2.04.15 2.97.43"/>
                            </svg>
                        </i><span>{{$t('EDIT_PROFILE')}}</span>
                    </a>
                    <a @click.prevent="action = 'PASSWORD'" class="profile--nav--link"><i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M16.28 13.61a4.147 4.147 0 01-4.18 1.03l-2.59 2.58c-.18.19-.55.31-.82.27l-1.2-.16c-.4-.05-.76-.43-.82-.82l-.16-1.2c-.04-.26.09-.63.27-.82l2.58-2.58c-.44-1.43-.1-3.05 1.03-4.18 1.62-1.62 4.26-1.62 5.89 0 1.62 1.61 1.62 4.25 0 5.88zM10.45 16.28l-.85-.86"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.395 10.7h.008"/></svg></i><span>Password</span></a>
                    <a @click.prevent="action = 'PREFER'" class="profile--nav--link"><i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M15.57 18.5v-3.9M15.57 7.45V5.5M15.57 12.65a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2zM8.43 18.5v-1.95M8.43 9.4V5.5M8.43 16.55a2.6 2.6 0 100-5.2 2.6 2.6 0 000 5.2z"/></svg></i> <span>Account preferences</span></a>                 
                </nav>         
            </div>

            <!-- PERFIL -->
            <div v-if="action === 'PROFILE'" class="modulo profile__edit col-78"> 
                <div class="profile__edit--header">
                    <h3>{{$t('EDIT_PROFILE')}}</h3>
                    <p>{{$t('PERSONAL_INFO_2')}}</p>        
                </div>  

                <div class="profile__edit--container">
                    <form action="">                                             
                        <div class="grid-form-100">
                            <div class="form--input">
                                <label for="name" class="required">{{$t('NAME')}}</label>
                                <input v-model="main.name" type="text" id="name" name="name" class="">                         
                            </div>
                        </div>                       
                        <div class="grid-form-50">
                            <div class="form--input">
                                <label for="mail" class="required">{{$t('EMAIL')}}</label>
                                <input v-model="main.email" type="text" id="mail" name="mail" class="">                         
                            </div>
                        </div>
                        <div class="grid-form-50">
                            <div class="form--input">
                                <label for="mail" class="required">{{$t('EMAIL_CONFIRM_1')}}</label>
                                <input v-model="main.emailConfirm" type="text" id="mail" name="mail" class="">                         
                            </div>
                        </div>
                        <div class="grid-form-100">
                            <div class="form--input content--buttons">
                                <button type="button" @click.prevent="sendProfile" class="button button--red">{{$t('PROFILE_UPDATE')}}</button>
                                <button type="button" @click.prevent="action = ''" class="button button--cancel">{{$t('CANCEL')}}</button>
                            </div>
                        </div>     
                    </form>  
                </div>
            </div>
            <!-- PERFIL -->


             <!-- PASSWORD -->
            <div v-if="action === 'PASSWORD'" class="modulo profile__edit col-78"> 
                <div class="profile__edit--header">
                    <h3>{{$t('PASS_SETTINGS')}}</h3>
                    <p>{{$t('PASS_DESC')}}</p>        
                </div>  

                <div class="profile__edit--container">
                    <form action="">                                             
                        <div class="grid-form-70">
                        <div class="form--input">
                            <label for="mail">{{$t('PASS_DIGIT')}}</label>
                            <input v-model="main.password" type="text" id="mail" name="mail" class="">                         
                        </div>
                        <div class="form--input">
                            <label for="mail" :class="[main.password&&main.password.length>0?'required':'']">{{$t('PASS_CONFIRM_1')}}</label>
                            <input v-model="main.passwordConfirm" type="text" id="mail" name="mail" class="">                         
                        </div>
                    </div>
          

                    <div class="grid-form-30">
                        <div class="form--input disclaimer">    
                            <p>{{$t('PASS_CREATE_1')}}</p>
                            <ul>
                            <li>{{$t('PASS_CREATE_2')}}</li>
                            <li>{{$t('PASS_CREATE_3')}}</li>
                            <li>{{$t('PASS_CREATE_4')}}</li>
                            <li>{{$t('PASS_CREATE_5')}}</li>
                        </ul>     
                        </div>
                    </div>
                        <div class="grid-form-100">
                            <div class="form--input content--buttons">
                                <button type="button" @click.prevent="sendPassword" class="button button--red">{{$t('ALTER_PASS')}}</button>
                                <button type="button" @click.prevent="action = ''" class="button button--cancel">{{$t('CANCEL')}}</button>
                            </div>
                        </div>     
                    </form>  
                </div>
            </div>
            <!-- PASSWORD -->

                        <div v-if="action === 'PREFER'" class="modulo profile__edit col-78"> 
                <div class="profile__edit--header">
                    <h3>Account preferences</h3>
                    <p>Control settings related to your account.</p>        
                </div>  

                <div class="profile__edit--container">
                    <form action="">                                             
                        <div class="grid-form-40">
                            <div class="form--input">
                                <label>{{$t('LANG')}}</label> 
                                <select @change="changeLang" v-model="$i18n.locale">
                                    <option value="en">English (United States)</option>
                                    <option value="pt">Portugês (Brasil)</option>
                                </select>
                            </div>       
                        </div>
                
                        <div class="grid-form-100">
                            <div class="form--input content--buttons">
                                <button type="button" @click.prevent="sendPassword" class="button button--red">Save</button>
                                <button type="button" @click.prevent="action = ''" class="button button--cancel">Cancel</button>
                            </div>
                        </div>     
                    </form>  
                </div>
            </div>
            <!-- Language -->



           

        </div>

    </section>
</template>


<script>
//import "@/assets/logged/app.min.css";

export default {
    props: {
        search: String,
        propiedades: Object
    },
    data(){
        return{
            main:{
                name:'',
                email:'',
                emailConfirm:'',
                password:'',
                passwordConfirm:'',
                role:{name:''}
            },
            regex : {
				imei : /^[0-9_.-]*$/,
				cnpj : /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/,
				phone : /^((\(\d{2}\) ?)|(\d{5}-))?\d{5}-\d{4}|\d{3}$/,
				email : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				cep : /^[0-9]{5}-?[0-9]{3}$/,
                senha : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
			},
            action: 'PROFILE'
        }
    },
    created() {
        document.title= 'Profile | ' + document.title.substring(document.title.indexOf('|') + 1)
        this.$store.dispatch("auth/alterMenu", "My Profile");
        this.propiedades.descricao = 'Manage your profile information.'
        this.propiedades.helperTitle = 'Manage profile information.'
        this.propiedades.helperDesc = '- Update user data<br/>- Change password';
    },
    mounted(){
        this.getUser();
    },
    computed:{
        validProfile : function(){
            return{
                name:!!this.main.name && this.main.name.length > 0,
                email:!this.main.email || this.main.email.length <= 0 || (!!this.main.email && this.main.email.length > 0 && this.regex.email.test(this.main.email)),
                emailConfirm:this.main.email === this.main.emailConfirm,
                //password:!this.main.password || this.main.password.length <= 0 || (!!this.main.password && this.main.password.length > 0 && this.regex.senha.test(this.main.password)),
                //passwordConfirm:this.main.password === this.main.passwordConfirm,
            }
        },
        validPassword : function(){
            return{
                //name:!!this.main.name && this.main.name.length > 0,
                //email:!this.main.email || this.main.email.length <= 0 || (!!this.main.email && this.main.email.length > 0 && this.regex.email.test(this.main.email)),
                //emailConfirm:this.main.email === this.main.emailConfirm,
                password:!this.main.password || this.main.password.length <= 0 || (!!this.main.password && this.main.password.length > 0 && this.regex.senha.test(this.main.password)),
                passwordConfirm:this.main.password === this.main.passwordConfirm,
            }
        },

        isValidProfile : function(){
            var ret = true
            Object.entries(this.validProfile).forEach(([key, value]) => {
                if(!value)
                    ret = false;
            });
            return ret;
        },

        isValidPassword : function(){
            var ret = true
            Object.entries(this.validPassword).forEach(([key, value]) => {
                if(!value)
                    ret = false;
            });
            return ret;
        },
    },
    methods:{
        changeLang(){
            if(this.$store.state.auth.user)
                this.$store.dispatch('auth/changeLang', [this.$store.state.auth.user.id, this.$i18n.locale]);
	    },
        getUser(){
            this.$store.dispatch("user/getUser", this.$store.state.auth.user.id).then(
                resp =>{
                    this.main = resp.data;
                    this.main.emailConfirm = this.main.email;
                },
            ).catch(erro =>{
                console.log('ERROR->'+erro);
            });
        },
        sendProfile(){
            if(this.isValidProfile){
                var form = {};
                form.name = this.main.name;
                form.email = this.main.email;
                this.$store.dispatch("user/updateUser", [this.$store.state.auth.user.id, form]).then(
                    resp =>{
                        if(resp === 'CREATED'){
                            this.getUser();

                            this.propiedades.feedback.status = 'SUCCESS'
                            this.propiedades.feedback.message = 'Usuário atualizado com sucesso.'
                        }else{
                            this.propiedades.feedback.status = 'DANGER'
                            this.propiedades.feedback.message = resp.data;
                        }
                    },
                ).catch(erro =>{
                    console.log('ERROR->'+erro);
                    this.propiedades.feedback.status = 'DANGER'
                    this.propiedades.feedback.message = "Erro ao atualizar usuário";

                });

            }else{
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = 'Verifique todos os campos obrigatórios.'
            }
        },

        sendPassword(){
            if(this.isValidPassword){
                var form = {};
                form.password = this.main.password;
                form.passwordConfirm = this.main.passwordConfirm;
                this.$store.dispatch("user/updateUser", [this.$store.state.auth.user.id, form]).then(
                    resp =>{
                        if(resp === 'CREATED'){
                            this.getUser();

                            this.propiedades.feedback.status = 'SUCCESS'
                            this.propiedades.feedback.message = 'Usuário atualizado com sucesso.'
                        }else{
                            this.propiedades.feedback.status = 'DANGER'
                            this.propiedades.feedback.message = resp.data;
                        }
                    },
                ).catch(erro =>{
                    this.propiedades.feedback.status = 'DANGER'
                    this.propiedades.feedback.message = "Erro ao atualizar usuário";

                });

            }else{
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = 'Verifique todos os campos obrigatórios.'
            }
        }
    }
};
</script>