import { createStore } from "vuex";
import { auth } from "./auth.module";
import { arch } from "./architects.module";
import { dash } from "./dashboard.module";
import { news } from "./news.module";
import { user } from "./user.module";
import { downloads } from "./downloads.module";
import { videos } from "./videos.module";
import { invoice } from "./invoice.module";


const store = createStore({
  modules: {
    auth,
    arch,
    dash,
    news,
    user,
    downloads,
    videos,
    invoice
  },
});

export default store;
