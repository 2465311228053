<template>
    <header class="header__admin">

        <div class="header__admin--form" style="border: 0"> 
            <!-- <input id="srch" @input="this.$emit('searching', search)" class="search" type="search" placeholder="Search all..." v-model="search"> -->
            <!-- <button><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.97 88"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M55.07,0a31.22,31.22,0,0,0-31,31.3,31.69,31.69,0,0,0,6.6,19.3L1.28,80.4a4.51,4.51,0,0,0,0,6.3A4.31,4.31,0,0,0,4.37,88a4.4,4.4,0,0,0,3.21-1.3L37,56.9a30.43,30.43,0,0,0,18,5.8A31.21,31.21,0,0,0,86,31.4C86,14.2,72.18,0,55.07,0Zm0,54.8a23.22,23.22,0,0,1-23-23.4,23,23,0,1,1,46,0A23.22,23.22,0,0,1,55.07,54.8Z"/></g></g></svg></button> -->
         </div>
            <div class="header__admin--container">
                <div class="header__info">
                    <i class="header__info--icon" @click=" isInfopage = !isInfopage" v-bind:class="{ active: !isInfopage }" >
                        <svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 2H8C4 2 2 4 2 8v13c0 .55.45 1 1 1h13c4 0 6-2 6-6V8c0-4-2-6-6-6z"/>
                         <path class="line-dialog-1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M7 9.5h10M7 14.5h7"/>
                        <path class="line-dialog-2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" d="M7 9.5h10M7 14.5h7"/>
                         </svg></i>       
                    <transition name="slide-fade">
                        <div @mouseout="isInfopage = true;" @mouseleave="isInfopage = true;" class="info__top" v-show="!isInfopage">
                            <h6 v-html="propiedades.helperTitle"></h6>
                            <p v-html="propiedades.helperDesc"></p>              
                        </div>
                    </transition>
                </div>
                       
                <div class="header__user">
                    <div class="header__user--avatar" id="clickOut" @click="propiedades.mobileShow = !propiedades.mobileShow" v-bind:class="{ active: propiedades.mobileShow }">               
                        {{user.name.substring(0, 1)+user.name.substring(user.name.lastIndexOf(' ')+1, user.name.lastIndexOf(' ')+2)}}
                    </div>
                    <transition name="slide-fade">
                        <div v-click-outside="onClickOutside" class="user__top" v-show="propiedades.mobileShow" >
                            <div class="user__hello">
                                <p class="header__user--avatar">{{user.name.substring(0, 1)+user.name.substring(user.name.lastIndexOf(' ')+1, user.name.lastIndexOf(' ')+2)}}</p>
                                <div class="user__hello--container">
                                    <p>{{$t('HELLO')+', '+user.name}}</p>
                                    <span>{{user.role.name}}</span>
                                </div>
                            </div>
                            
                            <router-link @click="propiedades.mobileShow = false" v-bind:class="{'active':url.indexOf('profile')>= 0}" to="/portal/profile">{{$t('PROFILE')}}</router-link>
                            <!-- <a href="">Settings</a> -->
                            <!-- <a href="">Tema</a> -->
                            <hr>
                            <a href="#" @click="logOut">{{$t('LOGOUT')}}</a>                  
                        </div>
                    </transition>
                </div>
        </div>
    </header>
</template>
<script>

export default {
    props:{
        propiedades: Object
    },
    data(){
        return{
            url: window.location.pathname,
            user: {},
            menu:"",
            search:'',
            isInfopage: true
        }
    },

    created(){
        this.user = this.$store.state.auth.user;
    },
    methods:{
        logOut() {
            this.$store.dispatch('auth/logout');
        },
        onClickOutside(e) {
            if(e.target.id !== 'clickOut')
                this.propiedades.mobileShow = false;
        },
    }
}
</script>
