import Invoice from '../services/invoice.service';

const user = JSON.parse(localStorage.getItem('user'));

export const invoice = {
  namespaced: true,
  actions: {
    getInvoicesProducts({ commit }, args) {
      return Invoice.getInvoicesProducts(args[0], args[1], args[2], args[3], args[4], args[5]).then(
        invoices => {
          return Promise.resolve(invoices);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    // getArquitect({ commit },id) {
    //   return ArchService.getArquitect(id).then(
    //     (arch) => {
    //       return Promise.resolve(arch);
    //     },
    //     error => {
    //       return Promise.reject(error);
    //     }
    //   );
    // },
    approve({ commit },args) {
      return Invoice.approve(args[0], args[1]).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    reject({ commit }, args) {
      return Invoice.reject(args[0], args[1]).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
  }
};
