<template>
    
        <Menu :propiedades="propiedades"/>
        <section class="main__content">
            <!-- alerts types ==========
                class:
                    blue: default
                    danger: .danger
                    alert: .warning
                    ok: .success
            
            ========== -->
            <div class="side__alert" id="feedback"
                :class="{
                    success : propiedades.feedback.status=='SUCCESS',
                    warning : propiedades.feedback.status=='WARNING', 
                    danger : propiedades.feedback.status=='DANGER',
                    blue : propiedades.feedback.status=='INFO',
                    'active-alert' : propiedades.feedback.status !== ''}">
                <div class="side__alert--header">
                    <h6>{{propiedades.feedback.status}}</h6>
                        <button @click.prevent="propiedades.feedback.status = ''" class="alert--close" title="Close">
                            <svg  viewBox="0 0 14 14">
                                <path d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z"  fill-rule="evenodd" title="Close" alt="Close" />
                            </svg>
                        </button> 
                </div>
                <div class="side__alert--container">
                    <p>{{propiedades.feedback.message}}</p>
                </div>
            </div>
            <!-- alerts -->
            <Header :propiedades="propiedades" @searching="passSearch"/>
            <section class="wrapper">
                <div class="header__intern col-100">        
                    <div class="header__intern--title ">
                        <h1>{{$store.state.auth.menu}}</h1>
                        <p v-html="propiedades.descricao"></p>
                    </div>       
                </div>
                <router-view :search="search" :propiedades="propiedades"/>
                <footer class="footer col-100"><span id="ano">{{new Date().getFullYear()}}</span>{{$t('LEGAL_LG_3')}}</footer>
                <!-- <div class="map--column">
                    <h6>{{$t('LANG')}}</h6>
                    <select @change="changeLang" v-model="$i18n.locale">
                        <option>en</option>
                        <option>pt</option>
                    </select>
                </div> -->
            </section>
        </section>
    
</template>


<script>
import Menu from '@/components/static/Menu.vue'
import Header from '@/components/static/Header.vue'

export default{
    components:{
        Menu,
        Header,
    },
    data (){
        return {
            search: '',
            propiedades:{
                mobileShow: false,
                descricao:'',
                helperDesc:'',
                feedback:{
                    status:'',
                    message:'',
                    timing:''
                },
            }
        }
    },
    watch : {
        'propiedades.feedback.status': function (val) {
            if(val !== ''){
                clearTimeout(this.propiedades.feedback.timing);  
                window.scrollTo(0,0);
            }var self = this;
            this.propiedades.feedback.timing = setTimeout(function(){self.propiedades.feedback.status = '';}, 5000);
        },
    },
    created(){
        if(!this.$store.state.auth.justLogged)
            this.$store.dispatch("auth/refreshTokenStart")
        this.user = this.$store.state.auth.user;
    },
    methods : {
        passSearch(search){
            this.search = search;
        },
        changeLang(){
            if(this.$store.state.auth.user)
                this.$store.dispatch('auth/changeLang', [this.$store.state.auth.user.id, this.$i18n.locale]);
	    }
    }
}
</script>

<style lang="sass" src="../assets/admin/sass/app.sass">
</style>



