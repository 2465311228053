import NewsService from '../services/news.service';

export const news = {
  namespaced: true,
  actions: {
    getNews({ commit }, args) {
      return NewsService.getNews(args[0]?.toString(), args[1]?.toString(), args[2]?.toString(), args[3]?.toString(), args[4]?.toString(), args[5]?.toString(), args[6]?.toString(), args[7]?.toString()).then(
        news => {
          return Promise.resolve(news);
        },
        error => {
          return Promise.reject(error);
        }
      ).catch(e =>{
        return Promise.reject(e)
      });
    },

    deleteNews({ commit }, id){
      return NewsService.deleteNews(id).then(
        success => {
          return Promise.resolve("DELETED")
        }, 
        error =>{
          return Promise.reject(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },

    publish({ commit }, id){
      return NewsService.publish(id).then(
        success => {
          return Promise.resolve("PUBLISHED")
        }, 
        error =>{
          return Promise.reject(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },

    unpublish({ commit }, id){
      return NewsService.unpublish(id).then(
        success => {
          return Promise.resolve("UNPUBLISHED")
        }, 
        error =>{
          return Promise.reject(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },

    getNewsId({ commit }, id){
      return NewsService.getNewsId(id).then(
        news => {
          return Promise.resolve(news);
        }, 
        error =>{
          return Promise.reject(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },

    create({ commit }, form){
      return NewsService.create(form).then(
        success => {
          return Promise.resolve(success)
        }, 
        error =>{
          return Promise.reject(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    },

    update({ commit }, form){
      return NewsService.update(form.id, form).then(
        success => {
          return Promise.resolve(success)
        }, 
        error =>{
          return Promise.reject(error)
        }).catch(e =>{
          return Promise.reject(e)
        });
    }
  },
  mutations: {
  }
};
