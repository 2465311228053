<template>
  <router-view />
  <!-- <footer class="footer col-100"><span id="ano">2021</span> - All Rights Reserved LG CNS</footer> -->
</template>

<script>

import EventBus from "./commons/EventBus";

export default {
  name: 'App',
  components: {
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
    if(this.currentUser?.language)
      this.$i18n.locale = this.currentUser?.language;
  },
  beforeDestroy() {
    EventBus.remove("logout");
  }
  
}
</script>