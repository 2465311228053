import { createWebHistory, createRouter } from "vue-router";
import Dashboard from "@/components/views/Dashboard.vue";
import Architects from "@/components/views/Architects.vue";
import Downloads from "@/components/views/Downloads.vue";
import Videos from "@/components/views/Videos.vue";
import News from "@/components/views/News.vue";
import Profile from "@/components/views/Profile.vue";
import Settings from "@/components/views/UserAdmin.vue";
import Campaigns from "@/components/views/Campaigns.vue";
import Products from "@/components/views/Products.vue";
import Logged from "@/views/Logged.vue";
import Login from "@/views/Login.vue";
import Registrations from "@/components/views/Registrations.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    children: [
      {path: 'login', redirect: '/'},
    ]
  },
  {
    path: "/portal",
    name: "Portal",
    component: Logged,
    children: [
      {path: '', redirect: '/portal/dashboard'},
      {path: 'dashboard', component: Dashboard},
      {path: 'downloads', component: Downloads},
      {path: 'videos', component: Videos},
      {path: 'news', component: News},
      {path: 'members', component: Architects},
      {path: 'settings', component: Settings},
      {path: 'profile', component: Profile},
      {path: 'campaigns', component: Campaigns},
      {path: 'products', component: Products},
      {path: 'registrations', component: Registrations}
    ]
  }
  /*{
    path: "/about",
    name: "About",
    component: About,
  },*/
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const userLogged = JSON.parse(loggedIn);
  const adminPages = ['/portal/settings'];
  const adminRequired = (adminPages.includes(to.path) && userLogged?.role?.id === 1) || !adminPages.includes(to.path);
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn && !adminRequired) {
      next('/');
  } else {
    next();
  }
});


export default router;