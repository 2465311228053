<template>
    <Modal @clickOut="clickOutside" v-show="isVisibility" @toggle-modal="toggleModal">
            <template v-slot:header--modal>
                <h2>{{$store.state.auth.menu+' '+$t('PROF')}}</h2>
                <p>{{$t('USER_ADMIN_SUBTITLE')}}</p>
            </template>       
           <template v-slot:container--modal>         
                <form>
                    <div v-if="isEdit" class="card__modal grid-form-100">
                        <div class="form--input  unlock__user check__type grid-form-100">
                            <span>{{$t('USER_ADMIN_LOCKED')}}</span>
                            <div class="check__type--content">
                                <label class="type" for="locked">{{$t('LOCKED')}}:
                                    <input v-model="user.blocked" value="true" type="radio" id="locked" >
                                </label>        
                                <label class="type" for="unlock">{{$t('UNLOCKED')}}:
                                    <input v-model="user.blocked" value="false" type="radio" id="unlock" >
                                </label>                             
                            </div>                                                
                        </div>
                    </div>
                    <!-- <div class="card__modal grid-form-100">
                        <div class="form--input check__type grid-form-100">
                            <span>Status User:</span>
                            <div class="check__type--content">
                                <label class="type" for="uName">Active:
                                    <input v-model="user.active" value='true' type="radio" name="active" id="uActive" >
                                </label>
                                <label class="type" for="uActive">Inative:  
                                    <input v-model="user.active" value='false' type="radio" name="active" id="uInactive" >
                                </label>                               
                            </div>                                                
                        </div> -->
                            <!-- OPEN SELECTET Inative -->
                        <!-- <div class="form--input check__type grid-form-100">
                            <label for="reason" class="required">Reason Inative</label>
                            <textarea type="text" id="reason"  name="reason"></textarea>
                        </div> -->
                    <!-- </div> -->
                    <div class="card__modal grid-form-100">
                
                        <div class="form--input grid-form-100">
                            <label for="nome">{{$t('NAME')}}:</label>            
                            <input v-model="user.name" type="text" name="nome" >
                        </div>
                        <div class="form--input grid-form-100">
                            <label for="mail">{{$t('EMAIL')}}:</label>            
                            <input v-model="user.email" type="text" name="mail"  >
                        </div>              
                                    
                        <div class="form--input grid-form-100">
                            <label for="nome">{{$t('USER_TYPE')}}:</label>            
                            <select name="Category" id="" v-model="user.role.id">
                                <option v-for="(role, index) in roles" :key="index" :selected="user.role.id === role.id" :value="role.id">{{role.name}}</option>
                            </select>
                        </div>
                        <!-- <div class="form--input grid-form-100">
                            <label for="nome">Allowed areas:</label>            
                            <div id="sample"></div>            
                        </div>               -->
                    </div>
                    <div class="card__modal grid-form-100">
                        <div class="form--input check__type grid-form-100">
                            <span>{{$t('USER_COMPANY')}}:</span>
                            <div class="check__type--content">
                                <label class="type" for="Company1">{{$t('USER_LG')}}:
                                    <input v-model="user.company" value="LG-USER" type="radio" name="Company" id="Company1" >
                                </label>
                                <label class="type" for="Company2">{{$t('USER_AGENCY')}}:  
                                    <input v-model="user.company" type="radio" value="AGENCY" name="Company" id="Company2" >
                                </label>
                                <label class="type" for="Company3">{{$t('OTHER_TIP')}}:  
                                    <input v-model="user.company" value="OTHER" type="radio" name="Company" id="Company3" >
                                </label>
                            </div>                                                
                        </div>
                    </div>
                

                    <div class="card__modal grid-form-100">
                        <div class="form--input info--modal grid-form-100">
                            <h5>{{$t('PASS_CREATION')}}</h5>
                            <p>{{$t('PASS_CREATION_1')}}</p>
                            <p>{{$t('PASS_CREATION_2')}}</p>
                        </div>
                        <div class="form--input grid-form-50">
                            <label for="nome">{{$t('PASS_DIGIT')}}:</label>            
                            <input v-model="user.password" type="text" name="nome"  >
                        </div>
                        <div class="form--input grid-form-50">
                            <label for="nome">{{$t('PASS_CONFIRM_2')}}:</label>            
                            <input v-model="user.passwordConfirm" type="text" name="nome"  >
                        </div>
                    </div>  
                    <div class="modal__btns grid-form-100">
                        <button @click="save" v-if="!isEdit" type="button" class="button button--gray-stroke">{{$t('SAVE')}}</button>
                        <button @click="update" v-if="isEdit" type="button" class="button button--gray-stroke">{{$t('UPDATE')}}</button>
                    </div>
                </form>
            </template>
    </Modal>
    <section class="wrapper">

        <section class="modulo list__grid archives architect--approved col-100">
                    <button @click.prevent="isEdit = false; toggleModal()" id="botaoAdd" class="button button--add"> <i><svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M12 12a5 5 0 100-10 5 5 0 000 10zM3.41 22c0-3.87 3.85-7 8.59-7 .96 0 1.89.13 2.76.37"/><path stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M22 18c0 .32-.04.63-.12.93-.09.4-.25.79-.46 1.13A3.97 3.97 0 0118 22a3.92 3.92 0 01-2.66-1.03c-.3-.26-.56-.57-.76-.91A3.92 3.92 0 0114 18a3.995 3.995 0 014-4c1.18 0 2.25.51 2.97 1.33.64.71 1.03 1.65 1.03 2.67zM19.49 17.98h-2.98M18 16.52v2.99"/></svg></i> {{$t('NEW_USER')}}</button>

            <header class="header-list">  
                <div class="header-list--content">
                    <h4>{{$t('SETTINGS')}}</h4>
                    <span class="amount">{{total +' '+$t('USERS')}} users</span>                               
                </div>  
                <div class="content__form">
                    <input id="srch" class="search" type="search" :placeholder="$t('SEARCH_USER')" v-model="sort.search">
                </div>                   
            </header>
            <div v-if="loading.approved.show" class="loading__table">{{loading.approved.msg}}</div>
            <table v-if="users && users.length > 0" class="list__itens" border="0" align="center" cellpadding="0" cellspacing="0" >
                
                <thead>
                    <tr>
                        <th @click="setSort('us.name')"  class="row__head"><span>{{$t('NAME')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('us.email')" class="row__head"><span>{{$t('EMAIL')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('us.createdAt')" class="row__head"><span>{{$t('CREATE_DATE')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('ro.name')"  class="row__head"><span>{{$t('USER_TYPE')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th @click="setSort('us.blocked')"  class="row__head"><span>{{$t('STATUS')}}<svg viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M10.45 6.72L6.73 3 3.01 6.72M6.73 21V3M13.55 17.28L17.27 21l3.72-3.72M17.27 3v18"/></svg></span></th>
                        <th class="row__head">{{$t('ACTIONS')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="itens" v-for="(use, index) in users" :key="index">
                        <td class="itens__description">{{use.name}} <br> <span class="user__type">{{use.role.name}}</span> </td>
                        <td class="itens__description">{{use.email}}</td>
                        <td class="itens__description">{{dataFormat(use.createdAt)}}</td>
                        <td class="itens__description">{{use.role.name}}</td>
                        <td>
                            <span class="status__members" :class="[use.blocked?'rejected':'active']">{{use.blocked?$t('BLOCKED'):$t('ACTIVE')}}</span>
                        </td>
                        <td><button id="btnShowProf" @click.prevent="editing(use)" class="button button--gray-stroke">{{$t('PROFILE_SHOW')}}</button> </td>
                    </tr>
                </tbody>
            </table>

            <div class="pagination">
                <span>{{(5*page>=total?total:(page<=1?page:(page*5)-5))+'-'+(5*page>=total?total:5*page)+$t('OF')+' '+total+' '+$t('ITEMS')}}</span>
                <div class="pagination--content">
                   <button @click="previousPage($event)" :disabled="page <= 1" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></button>
                    <ul class="pagination--list">
                        <li><a @click="previousPage($event)" href="#" v-show="page > 1" class="btn--pagination">{{page-1}}</a> </li>
                        <li><a href="#" class="btn--pagination active">{{page}}</a></li>
                        <li><a @click="nextPage($event)" href="#" v-show="page*5 < total" class="btn--pagination">{{page+1}}</a> </li>
                    </ul>
                    <button @click="nextPage($event)" :disabled="page*5 >= total" class="btn__pagination--arrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></button>
                </div>
            </div>
        </section>
    </section>
</template>


<script>
import dt from 'date-and-time';

import Modal from "@/components/Modal"

export default {
    
    name: "Login",
    components: {
        Modal,
    },
  props: {
      search: String,
      propiedades: Object
  },
  data() {
    return {
        loading:{
            approved: {
                show: false,
                msg: '',
                loading: ''
            },
            rejected: {
                show: false,
                msg: '',
                loading: ''
            },
            toAprove: {
                show: false,
                msg: '',
                loading: ''
            }
        },
        isEdit:false,
        users:[],
        user:{role:{id:-1}, active:true, blocked:false},
        roles:[],
        view:false,
        page:1,
        sort:{
            field:'us.createdAt',
            order:'desc',
            search: '',
            role: 'ADMIN'
        },
        total:0,
        action:'',
        isVisibility: false,
        regex : {
            imei : /^[0-9_.-]*$/,
            cnpj : /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}$/,
            phone : /^((\(\d{2}\) ?)|(\d{5}-))?\d{5}-\d{4}|\d{3}$/,
            email : /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            cep : /^[0-9]{5}-?[0-9]{3}$/,
            senha : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
        },
    };
  },
  watch: {
    'sort.search' : function(val){
        this.page=1,
        this.load();
    },
    isVisibility : function(val){
        if(!this.isVisibility)
            this.user = {role:{id:-1}, active:true, blocked:false}
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.user?.role?.id === 1;
    },
    valid : function(){
        return{
            name:!!this.user.name && this.user.name.length > 0,
            email:!this.user.email || this.user.email.length <= 0 || (!!this.user.email && this.user.email.length > 0 && this.regex.email.test(this.user.email)),
            password:!this.user.password || this.user.password.length <= 0 || (!!this.user.password && this.user.password.length > 0 && this.regex.senha.test(this.user.password)),
            passwordConfirm:this.user.password === this.user.passwordConfirm,
            role:!!this.user.role.id && this.user.role.id >= 0,
            company:!!this.user.company && this.user.company.length > 0,
            blocked: typeof this.user.blocked == "boolean" || this.user.blocked === 'false' || this.user.blocked === 'true',
            //active: typeof this.user.active == "boolean" || this.user.active === 'false' || this.user.active === 'true',
            //reason: !!this.user.active || this.user.active === 'true' || (!!this.user.reason && this.user.reason.length > 0)
        }
    },

    validCreate : function(){
        var target = {}
        Object.assign(target, this.valid);
        target.password=!!this.user.password && this.user.password.length > 0 && this.regex.senha.test(this.user.password);            
        return target;
    },

    isValid : function(){
        var ret = true
        Object.entries(this.valid).forEach(([key, value]) => {
            if(!value)
                ret = false;
        });
        return ret;
    },

    isValidCreate : function(){
        var ret = true
        Object.entries(this.validCreate).forEach(([key, value]) => {
            if(!value)
                ret = false;
        });
        return ret;
    },
  },
  mounted(){
      this.view = false;
  },
  created() {
    if(!this.isAdmin)
        this.$router.push("/portal/dashboard");
    document.title= 'Users | ' + document.title.substring(document.title.lastIndexOf('|') + 1)
    this.propiedades.descricao = 'Manage the users that have access to the portal.'
    this.propiedades.helperTitle = 'Place to manage the users that have access to portal.'
    this.propiedades.helperDesc = '- Add or change users'+
    '<br/>- Lock/Unlock users<br/>- Company setup'
    var self = this;
    this.loadRoles();
    this.load()
    this.$store.dispatch("auth/alterMenu", "Users");
  },
  
  methods: {
    dataFormat(dat){
        return dt.format(dt.parse(dat, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), 'DD-MM-YYYY HH:MM');
    },
    nextPage(e){
        e.preventDefault();
        this.page+=1;
        this.load();
    },
    previousPage(e, type){
        e.preventDefault();
        this.page-=1;
        this.load();
    },
    load(){
        this.$store.dispatch("user/getUsers", [null, (this.page-1)*5, 5, this.sort.field, this.sort.order, this.sort.search, this.sort.role]).then((resp) =>{
            this.users = resp.data.data;
            this.total = resp.data.totalCount;
        });
    },
    loadRoles(){
        this.$store.dispatch("user/getRoles").then((resp) =>{
            this.roles = resp.data.filter(function(obj){return obj.name !== 'PARTICIPANT'});
        });
    },
    update(){
        if(this.isValid){
            var form = {};
            form.id = this.user.id;
            //form.active = typeof this.user.active == "boolean"?this.user.active:(this.user.active === 'true'?true:false);
            form.active = true;
            form.blocked = typeof this.user.blocked == "boolean"?this.user.blocked:(this.user.blocked === 'true'?true:false);
            form.name = this.user.name;
            form.email = this.user.email;
            form.company = this.user.company;
            form.role ={};
            form.role.id = this.user.role.id;
            if(this.user.password&&this.user.password.length>0){
                form.password = this.user.password;
                form.passwordConfirm = this.user.passwordConfirm;
            }
            this.$store.dispatch("user/updateUser", [form.id, form]).then(
                resp =>{
                    if(resp === 'CREATED'){
                        this.load();

                        this.propiedades.feedback.status = 'SUCCESS'
                        this.propiedades.feedback.message = 'Usuário atualizado com sucesso.'
                        this.toggleModal();
                    }else{
                        this.propiedades.feedback.status = 'DANGER'
                        this.propiedades.feedback.message = resp.response.data.message;
                    }
                },
            ).catch(erro =>{
                console.log('ERROR->'+erro);
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = "Erro ao atualizar usuário";

            });

        }else{
            this.propiedades.feedback.status = 'DANGER'
            this.propiedades.feedback.message = 'Verifique todos os campos obrigatórios.'
        }
    },
    editing(obj){
        this.isEdit = true;
        this.$store.dispatch("user/getUser", obj.id).then((resp) =>{
            this.user = resp.data;
            this.toggleModal();
        });
    },
    save(){
        if(this.isValidCreate){
            var form = {};
            form.id = this.user.id;
            //form.active = typeof this.user.active == "boolean"?this.user.active:(this.user.active === 'true'?true:false);
            form.active = true;
            form.blocked = typeof this.user.blocked == "boolean"?this.user.blocked:(this.user.blocked === 'true'?true:false);
            form.name = this.user.name;
            form.email = this.user.email;
            form.company = this.user.company;
            form.role ={};
            form.role.id = this.user.role.id;
            form.password = this.user.password;
            form.passwordConfirm = this.user.passwordConfirm;
            form.optIn = true;
            this.$store.dispatch("user/createUser", form).then(
                resp =>{
                    if(resp === 'CREATED'){
                        this.load();

                        this.propiedades.feedback.status = 'SUCCESS'
                        this.propiedades.feedback.message = 'Usuário criado com sucesso.'
                        this.toggleModal();
                    }else{
                        this.propiedades.feedback.status = 'DANGER'
                        this.propiedades.feedback.message = resp.response.data.message;
                    }
                },
            ).catch(erro =>{
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = "Erro ao atualizar usuário";
            });
        }else{
            this.propiedades.feedback.status = 'DANGER'
            this.propiedades.feedback.message = 'Verifique todos os campos obrigatórios.'
        }
    },
    setSort(field){
        this.sort.field = field;
        this.sort.order = this.sort.order==='desc'?'asc':'desc';
        this.page=1
        this.load(); 
    },
    toggleModal(e, obj, action){
        this.isVisibility = !this.isVisibility;
    },
    clickOutside(e) {
        if(e.target.id !== 'botaoAdd' && e.target.id !== 'btnShowProf')
            this.isVisibility = false;
    },
  }
};
</script>