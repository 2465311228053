import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import setupInterceptors from './services/setupInterceptor';
import VueApexCharts from "vue3-apexcharts";
import { createI18n } from 'vue-i18n';

setupInterceptors(store);


const messages = {
  en: {
      NEWS_FULL	:'Read full story',
      SEARCH	:'Search',
      ORDER_BY	:'Filter',
      START	:'Home',
      ABOUT	:'About us',
      NEWS	:'News',
      DOWNLOADS	:'Downloads',
      DOWNLOAD	:'Download',
      VIDEOS	:'Video',
      CONTACT_US	:'Contact Us',
      HELLO	:'Hello',
      READ_MORE	:'Read more',
      READ_MORE_NEWS:"LER NOTÍCIA COMPLETA",
      FULL_NAME	:'Full Name',
      EMAIL	:'E-mail',
      PHONE_CONTACT	:'Phone Number',
      DESC	:'Description',
      ATTACH	:'Attach file',
      SEND	:'Send',
      MAGENTA_DESC	:'LG Magenta is a content platform designed to bring LG and architects closer together.',
      MAGENTA_DESC_1	:'We bring you the main trends, offer master classes from renowned architects and designers, and also provide LG assets to elaborate your projects.',
      MAGENTA_DESC_2	:'In addition, by registering on our platform, you get exclusive benefits and classes for business management and branding.',
      ACCOUNT	:'Access my account',
      LOGIN_DESC	:'Enter your e-mail address and password to access your account and enjoy the benefits and exclusivities of LG Magenta.',
      EMAIL_DIGIT	:'E-mail',
      PASS_DIGIT	:'Password',
      FORGOT_PASS	:'Forgot Password?',
      IN	:'Sign in',
      REGISTER	:'Register now',
      PROFILE	:'My Profile',
      CPF	:'CPF',
      BORN	:'Your birthday',
      PROFESSION	:'Profession',
      CAU_CFT	:'CAU or CFT',
      WORK_TIME	:'Time in the market',
      INSTA	:'Instagram',
      LOGOUT	:'Logout',
      EDIT_PROFILE	:'Edit Profile',
      ALTER_PASS	:'Change password',
      SAVE	:'Save',
      NEW_PASS	:'New Password',
      PROFESSION_NUMBER	:'Professional number',
      INSTA_PROFESSIONAL	:'Professional Profile (Instagram)',
      EMAIL_CONFIRM	:'Confirm e-mail',
      NEXT	:'Next',
      EXIT:'Exit',
      LOGIN:'Login | Register',
      REGISTER: 'Sign In',
      NOT_REGISTER:'Not registered',
      MAGENTA_ALTS:'LG & Magenta - Tendencias e Benefícios',
      LANG:'Language',
      LOGO_LG:'Logo LG',
      LG_MAGENTA:'LG Magenta',
      LEGAL_LG: 'All rights reserved. |  © 2021 LG Electronics',
      VERSION: 'Version ',
      ENV:'Enviroment ',
      MENU:'Menu',
      SUPPORT:'Support',
      PROF:'Profile',
      CONTENTS:'Contents',
      SIGN_IN: 'Sign in to connect with exclusive contents that only participants',
      SIGN_IN_1: 'has access.',
      PERSONAL_INFO:'Personal information',
      REQUIRED: '*Required Fields',
      REQUIRED_1: 'Required Field.',
      FILL_NAME: 'Fill your name, please.',
      FILL_CPF:'Fill a valid CPF, please.',
      FILL_EMAIL:'Fill a valid Email, please.',
      FILL_FIELD:'Fill this field, please.',
      FILL_OPTION:'Select an option, please.',
      MAGENTA_INFO:'O LG Magenta é destinado exclusivamente a profissionais das áreas de arquitetura, designer de interiores e engenharia civil. Inserir número do registro: CAU, CREA, ABD ou certificado de curso das áreas mencionadas que comprovem sua formação e atuação na área.',
      PROFESSION_REASON:'Why professional record?',
      GOOGLE_TIP:'Google',
      FRIEND_TIP:'Friend indication',
      INFLUENCER_TIP:'Influencer indication',
      OTHER_TIP:'Others',
      INSTA_KEYUP:'Digit influencer instagram(Ex: @MyInfluencer)',
      PASS_CREATE:'Create a new password',
      PASS_CREATE_1:'Password must have:',
      PASS_CREATE_2:'6 or more characters',
      PASS_CREATE_3:'At least a number',
      PASS_CREATE_4:'An upper case letter',
      PASS_CREATE_5:"A especial character ({'@'}*!%:,.)",
      PASS_REQUIRED:'Digit a valid password, please.',
      PASS_REQUIRED_2:'Confirmm your password, please.',
      PASS_CONFIRM:'Confirmm password',
      LEGAL_ACCEPT:'Accept Terms and Conditions',
      PROFESSIONAL_CONFIRM:'I confirm that I am an architect, interior designer, or engineer and wish to participate in LG Magenta.',
      TERM:'Terms',
      LEGAL_LG_1:'I agree to share my data to receive information about LG products and offers based on studies of my profile. To learn more, see our ',
      LEGAL_LG_2:'privacy policy.',
      FINISH:'Finish',
      SIGN_IN_GOODBYE:'Thank you for signing up!',
      SIGN_IN_GOODBYE_1:'We are happy that you are interested in joining LG Magenta. Soon, you will receive an e-mail from us confirming your participation and you will be able to enjoy the exclusive benefits of the program.',
      SIGN_IN_GOODBYE_2:"While we validate your data, you can learn more about our products by browsing LG's website.",
      END_1:"End",
      MORE_DOWNLOADS:"More downloads",
      MORE_NEWS:"more news",
      LAST_NEWS:"Last news",
      NEWEST:'Newest',
      OLDEST:'Oldest',
      WATCH:'Watch',
      TUTO:'Tutorials',
      RECOMMENDATIONS:'Recommended for you',
      VIDEO_RELATED:'Related videos',
      PHONE:'Phone',
      PERSONAL_INFO_1:'Personal data',
      FILL_DATA:'Fill a date, please.',
      FILL_PHONE:'Fill your phone number, please.',
      FILL_INSTA:'Fill your Instagram, please.',
      CPF_CHANGE:'To change your CPF please contact support.',
      CONTACT:'Contact',
      PASS:'Password',
      PASS_ERROR: 'Passwords do not match.',
      LOGIN_1:'Login',
      NOT_CREATED:'You do not have an account yet?',
      PARTICIPANT_IN:'Some benefits of being an LG Magenta participant:',
      PARTICIPANT_IN_1:'Get first-hand access to LG releases and content',
      PARTICIPANT_IN_2:"Stay on top of the world's main trends in architecture and interior design",
      PARTICIPANT_IN_3:'Download 3D/REVIT blocks of the products to apply in your projects',
      PARTICIPANT_IN_4:'Specialized service and access to our showroom',
      HELP_CONTACT:'How we can help you?',
      HELP_CONTACT_1:'In order for us to help you, please provide some information.',
      HELP_CONTACT_2:'Send a message to our team explaining your doubt or occurrence, so that we can help you. Attach prints, photos or documents for a more complete report.',
      PASS_RECOVERY:'Recover password',
      PASS_RECOVERY_1:'For your security we send a code to your e-mail.',
      FILL_CODE:'Please confirm the code.',
      CODE_VALID:'Validate Code',
      CODE_RESEND:'Resend Code',
      PASS_RESET:'Reset Password',
      WELCOME:'Welcome to the architects portal',
      SEND_TOKEN:'Send my Token',
      TOKEN:'Token',
      BACK:'Go Back',
      RESET:'Reset',
      LEGAL_LG_3:'- All Rights Reserved LG CNS - Version: ',
      ENVIRONMENT:'Environment: ',
      USER:'User',
      USERS:'Users',
      SETTINGS:'Settings',
      MEMBERS:'Members',
      DASH:'Dashboard',
      MEMBERS_DESC:'Below, the person that requires approval to login on the portal.',
      NAME:'Name',
      LG_MAGENTA_KNOW:'How did you get to LG Magenta',
      REASON:'Reason',
      FILL_REASON:'Fill out a reason, please',
      CANCEL:'Cancel',
      EDIT:'Edit',
      REJECT:'Reject',
      APPROVE:'approve',
      APPROVE_TO:'To approve',
      APPROVE_REJECT:'Approve/Reject',
      SEARCH_NAME:'Search',
      SEARCH_USER:'Search by user',
      JOIN:'Join Date',
      ACTIONS:'Actions',
      OF:'of',
      ITEMS:'items',
      STATUS:'Status',
      PROFILE_SHOW:'show profile',
      PERIOD:'Period',
      GO:'GO',
      MEMBERS_AVAI:'Members available',
      DOWNLOAD_EXCEL:'Download Excel',
      ACTIVE:'Active',
      INACTIVE:'Inactive',
      MEMBERS_ANALYSIS:'Members on analysis',
      GO_PAGE:'Go to page',
      MEMBERS_ACCESS_MONTHLY:'Member access monthly',
      MEMBERS_ACCESS_AREA:'Member access top areas',
      TITLE_MAIN:'Main title',
      DOWNLOAD_SUMMARY:'Summary Download',
      BANNER_MAIN:'Show in Main Banner',
      CATEGORY:'Category',
      CAD:'CAD',
      PDF:'PDF',
      POWER_POINT:'Power Point',
      TAGS:'Tags',
      BANNER_DESKTOP:'Banner Desktop',
      BANNER_INFO:'Banner ja cadastrado, selecione novo arquivo para substituir.',
      BANNER_MOBILE:'Banner Mobile',
      DOWNLOAD_FILE:'Arquivo para Download',
      THUMB:'Thumb',
      REQUIRED_2:'Required',
      TITLE:'Title',
      CREATE_DATE:'creation Date',
      PUBLISH:'Publish',
      UNPUBLISH:'Unpublish',
      PUBLISHED:'Published',
      UNPUBLISHED:'Unpublished',
      SUMMARY_NEWS:'Summary News',
      YES:'Yes',
      NO:'No',
      CREATED:'Created',
      APPLY:'apply',
      PERSONAL_INFO_2:'Set Up Your Personal Information',
      EMAIL_CONFIRM_1:'Repeat emai',
      PROFILE_UPDATE:'Update profile',
      PASS_SETTINGS:'Password Settings',
      PASS_DESC:'Change or reset your account password',
      PASS_CONFIRM_1:'Repeat password',
      USER_ADMIN_SUBTITLE:'Administrative settings. Inform the users authority below.',
      USER_ADMIN_LOCKED:'User locked for more than 5 password attempts',
      LOCKED:'Locked',
      UNLOCKED:'Unlocked',
      USER_TYPE:'Type User',
      USER_COMPANY:'Company User',
      USER_LG:'LG user',
      USER_AGENCY:'Agency user',
      PASS_CREATION:'Password creation',
      PASS_CREATION_1:'Create an initial password for the new user - the password must contain: an uppercase letter, a number and a character.',
      PASS_CREATION_2:'In case of 5 attempts  with wrong credential, the user will be blocked. In that case is necessary to active the user again',
      PASS_CONFIRM_2:'Password again',
      UPDATE:'Update',
      NEW_USER:'Add new user',
      BLOCKED:'Blocked',
      SUMMARY_VIDEOS:'Summary Video',
      AUTOCAD:'Autocad',
      VIDEO_LINKS:'Video Link',
      PRODUTOS_LG:'Produtos LG',
      NAV_CAMP:'Campaigns',
      NAV_INV:'Invoices',
      NAV_PROD:'Products',
      INVOICES:'Invoices' ,  

      MODAL_ALERT_TITLE:'Do you want to delete these items?',
      MODAL_ALERT_DEC:'Click OK to confirm your action.',
      
      MODAL_TITLE_CAMP:'Campaign approval', 
      MODAL_TITLE_DESC:'Approve points records', 
      MODAL_BTN_INV:'View Invoice',
      MODAL_FORM_CNPJ:'CNPJ', 
      MODAL_FORM_DATE:'Date of sale', 
      MODAL_FORM_INV:'Invoice',   
      MODAL_FORM_VALUE:'Invoice Value', 
      MODAL_FORM_MODEL:'Model',
      MODAL_FORM_VALUE_PROD:'Product Value (Unit.)',
      MODAL_FORM_QTD:'Amount',
      MODAL_FORM_PROD:'Product',     
      MODAL_SUBTITLE:'Products',
      REG_TITLE_APRO:'Waiting for approval',
      REG_TITLE_APRO_TAG:'News',
      REG_TITLE_APROVADOS:'Approved',
      TABLE_CAMP:'CampCampaignanha',
      CAMP_FORM_REG:'Regulation',
      CAMP_FORM_START:'Start',
      CAMP_FORM_END:'End',
      CAMP_FORM_TITLE_PROD:'Participating Products',
      CAMP_FORM_LABEL_CAT:'Category',
      CAMP_FORM_LABEL_MOD:'Model',
      CAMP_FORM_LABEL_PONT:'Product point',
      CAMP_BTN_PROD:'Product',
      CAMP_BTN_CAD:'Show registration',
      FORM_ALERT_SPAN:'Required field',
      CAMP_TABELA_TITLE:'Campaign',
      CAMP_TABELA_TITLE_2:'Creation Date',
      PROD_LABEL_CAT:'Category',
      PROD_LABEL_MOD:'Model',
      PROD_LABEL_IMG:'Image',
      PROD_LABEL_DEC:'Description',
      PROD_TABLE_TITLE:'Product',
      PROD_TABLE_TITLE_2:'Category',
      PROD_TABLE_TITLE_3:'Creation Date',
    },
  pt: {
      NEWS_FULL	:'Ler noticia completa',
      SEARCH	:'Pesquisar',
      ORDER_BY	:'Ordernar por',
      START	:'Início',
      ABOUT	:'Sobre nós',
      NEWS	:'News',
      DOWNLOADS	:'Downloads',
      DOWNLOAD	:'Download',
      VIDEOS	:'Vídeos',
      CONTACT_US	:'Fale Conosco',
      HELLO	:'Olá',
      READ_MORE	:'Saiba mais',
      READ_MORE_NEWS:"LER NOTÍCIA COMPLETA",
      FULL_NAME	:'Nome completo',
      EMAIL	:'E-mail ',
      PHONE_CONTACT	:'Telefone para contato',
      DESC	:'Descrição',
      ATTACH	:'Anexar',
      SEND	:'Enviar',
      MAGENTA_DESC	:'A LG Magenta é uma plataforma de conteúdo feita para aproximar a Z e os arquitetos.',
      MAGENTA_DESC_1	:'Trazemos as principais tendências, oferecemos master classes de renomados arquitetos e designers e também disponibilizamos assets da LG para elaborar seus projetos.',
      MAGENTA_DESC_2	:'Além disso, ao se cadastrar em nossa plataforma, você conta com benefícios exclusivos e aulas para gestão de negócio e branding.',
      ACCOUNT	:'Acessar minha conta',
      LOGIN_DESC	:'Entre com seu e-mail e senha para acessar sua conta e aproveitar os benefícios e exclusividades do',
      EMAIL_DIGIT	:'Digite seu E-mail',
      PASS_DIGIT	:'Digite sua senha',
      FORGOT_PASS	:'Esqueceu a senha',
      IN	:'Entrar',
      REGISTER	:'Registrar-se agora',
      Profile	:'Meu perfil',
      CPF	:'CPF',
      BORN	:'Data de nascimento',
      PROFESSION	:'Profissão',
      CAU_CFT	:'CAU ou CFT',
      WORK_TIME	:'Tempo de atuação no mercado',
      INSTA	:'Instagram',
      LOGOUT	:'Sair da conta',
      EDIT_PROFILE	:'Editar Perfil',
      ALTER_PASS	:'Alterar senha',
      SAVE	:'Salvar',
      NEW_PASS	:'Nova senha',
      PROFESSION_NUMBER	:'Registro Profissional',
      INSTA_PROFESSIONAL	:'Perfil profissional (instagram)',
      EMAIL_CONFIRM	:'Confirmar e-mail',
      NEXT	:'Avançar',
      EXIT:'Sair',
      LOGIN:'Entrar | Registre-se',
      REGISTER: 'Registre-se',
      NOT_REGISTER:'Não sou cadastrado',
      MAGENTA_ALTS:'LG & Magenta - Tendencies and Benefits',
      LANG:'Idioma',
      LOGO_LG:'Logo LG',
      LG_MAGENTA:'LG Magenta',
      LEGAL_LG: 'Todos os direitos reservados. |  © 2021 LG Electronics',
      VERSION: 'Versão ',
      ENV:'Sistema de ',
      MENU:'Menu',
      SUPPORT:'Suporte',
      PROF:'Perfil',
      CONTENTS:'Conteúdo',
      SIGN_IN: 'Crie sua conta para para acessar os conteúdos exclusivos que somente participantes',
      SIGN_IN_1: 'têm acesso.',
      PERSONAL_INFO:'Informações pessoais',
      REQUIRED: '*Campos obrigatórios',
      REQUIRED_1: 'Campo obrigatorio.',
      FILL_NAME: 'Preencha seu nome, por favor.',
      FILL_CPF:'Preencha um CPF válido, por favor.',
      FILL_EMAIL:'Preencha um e-mail válido, por favor.',
      FILL_FIELD:'Preencha esse campo, por favor.',
      FILL_OPTION:'Selecione uma opção, por favor.',
      MAGENTA_INFO:'O LG Magenta é destinado exclusivamente a profissionais das áreas de arquitetura, designer de interiores e engenharia civil. Inserir número do registro: CAU, CREA, ABD ou certificado de curso das áreas mencionadas que comprovem sua formação e atuação na área.',
      PROFESSION_REASON:'Por que o registro profissional?',
      GOOGLE_TIP:'Google',
      FRIEND_TIP:'Indicação amigo(a)',
      INFLUENCER_TIP:'Indicação influenciador',
      OTHER_TIP:'Outros',
      INSTA_KEYUP:'Digite o instagram do influenciador (Ex: @MeuInfluenciador)',
      PASS_CREATE:'Crie a sua senha de acesso',
      PASS_CREATE_1:'Sua senha deve ter:',
      PASS_CREATE_2:'6 ou mais caracteres',
      PASS_CREATE_3:'Pelo menos um número',
      PASS_CREATE_4:'Um letra maiúscula',
      PASS_CREATE_5:"Um caracter especial ({'@'}*!%:,.)",
      PASS_REQUIRED:'Digite uma senha válida, por favor.',
      PASS_REQUIRED_2:'Confirme sua senha, por favor.',
      PASS_CONFIRM:'Confirmar senha',
      LEGAL_ACCEPT:'Termos e condições de aceite',
      PROFESSIONAL_CONFIRM:'Eu confirmo ser um(a) profissional da área de arquitetura, designer de interiores ou engenharia e desejo participar do LG Magenta.',
      TERM:'Termo de Uso',
      LEGAL_LG_1:'Aceito compartilhar meus dados para receber informações sobre produtos e ofertas da LG com base em estudos do meu perfil. Para saber mais, veja nossa ',
      LEGAL_LG_2:'política de privacidade.',
      FINISH:'Finalizar',
      SIGN_IN_GOODBYE:'Agradecemos pelo seu cadastro!',
      SIGN_IN_GOODBYE_1:'Ficamos felizes pelo seu interesse em fazer parte do LG Magenta. Em breve, você receberá o nosso e-mail com a confirmação da sua participação e poderá usufruir dos benefícios exclusivos do programa.',
      SIGN_IN_GOODBYE_2:'Enquanto validamos seus dados, aproveite e conheça mais sobre os nossos produtos navegando pelo site da LG.',
      END_1:"Fim",
      MORE_DOWNLOADS:"Mais Downloads",
      MORE_NEWS:"Mais notícias",
      LAST_NEWS:"Últimas notícias",
      NEWEST:'Mais recentes',
      OLDEST:'Mais antigas',
      WATCH:'Assistir',
      TUTO:'Tutoriais',
      RECOMMENDATIONS:'Recomendações pra você',
      VIDEO_RELATED:'Vídeos relacionados',
      PHONE:'Celular',
      PERSONAL_INFO_1:'Dados pessoais',
      FILL_DATA:'Preencha uma data, por favor.',
      FILL_PHONE:'Preencha seu Celular, por favor.',
      FILL_INSTA:'Preencha seu Instagram, por favor.',
      CPF_CHANGE:'Para alterar seu CPF entre em contato com o suporte.',
      CONTACT:'Contato',
      PASS:'Senha',
      PASS_ERROR: 'Senhas não estão iguais.',
      LOGIN_1:'Fazer Login',
      NOT_CREATED:'Ainda não possui uma conta?',
      PARTICIPANT_IN:'Alguns benefícios de ser um participante do LG Magenta:',
      PARTICIPANT_IN_1:'Tenha acesso a lançamentos e conteúdos da LG em primeira mão',
      PARTICIPANT_IN_2:'Fique por dentro das principais tendências da arquitetura e designer de interiores do mundo',
      PARTICIPANT_IN_3:'Faça download de blocos 3D/REVIT dos produtos para aplicar em seus projetos',
      PARTICIPANT_IN_4:'Atendimento especializado e acesso ao nosso showroom',
      HELP_CONTACT:'Como podemos te ajudar?',
      HELP_CONTACT_1:'Para que possamos te ajudar, por gentileza informe alguns dados.',
      HELP_CONTACT_2:'Envie uma mensagem para nossa equipe explicando sua dúvida ou ocorrido, para que possamos te ajudar. Anexe prints, fotos ou documentos para um relato mais completo.',
      PASS_RECOVERY:'Recuperar senha',
      PASS_RECOVERY_1:'Para a sua segurança enviamos um código para o seu e-mail.',
      FILL_CODE:'Por favor, confirme o códígo.',
      CODE_VALID:'Validar código',
      CODE_RESEND:'Reenviar código',
      PASS_RESET:'Trocar senha',
      WELCOME:'Bem-vindo ao Portal dos Arquitetos',
      SEND_TOKEN:'Enviar Token',
      TOKEN:'Token',
      BACK:'Voltar',
      RESET:'Alterar',
      LEGAL_LG_3:'- All Rights Reserved LG CNS - Version: ',
      ENVIRONMENT:'Environment: ',
      USER:'Usuário',
      USERS:'Usuários',
      SETTINGS:'Configurações',
      MEMBERS:'Membros',
      DASH:'Dashboard',
      MEMBERS_DESC:'Abaixo, pessoas que pediram aprovação para entrar no portal.',
      NAME:'Nome',
      LG_MAGENTA_KNOW:'Como chegou ao LG Magenta?',
      REASON:'Motivo',
      FILL_REASON:'Preencha uma razão, por favor',
      CANCEL:'Cancelar',
      EDIT:'Editar',
      REJECT:'Rejeitar',
      APPROVE:'Aprovar',
      APPROVE_TO:'Para aprovação',
      APPROVE_REJECT:'Aprovar/Rejeitar',
      SEARCH_NAME:'Pesquisar',
      SEARCH_USER:'Pesquisar por usuário',
      JOIN:'Data de inscrição',
      ACTIONS:'Ação',
      OF:'de',
      ITEMS:'itens',
      STATUS:'Status',
      PROFILE_SHOW:'Exibir perfil',
      PERIOD:'Periodo',
      GO:'IR',
      MEMBERS_AVAI:'Membros disponiveis',
      DOWNLOAD_EXCEL:'Download Excel',
      ACTIVE:'Ativo',
      INACTIVE:'Inativo',
      MEMBERS_ANALYSIS:'Membros em analise',
      GO_PAGE:'Ir para página',
      MEMBERS_ACCESS_MONTHLY:'Acesso mensal de membros',
      MEMBERS_ACCESS_AREA:'Áreas mais acessadas pelos membros',
      TITLE_MAIN:'Título',
      DOWNLOAD_SUMMARY:'Download resumo',
      BANNER_MAIN:'Exibir no banner principal',
      CATEGORY:'Categoria',
      CAD:'CAD',
      PDF:'PDF',
      POWER_POINT:'Power Point',
      TAGS:'Tags',
      BANNER_DESKTOP:'Banner Desktop',
      BANNER_INFO:'Banner ja cadastrado, selecione novo arquivo para substituir.',
      BANNER_MOBILE:'Banner Mobile',
      DOWNLOAD_FILE:'Download File',
      THUMB:'Thumb',
      REQUIRED_2:'Obrigatório',
      TITLE:'Título',
      CREATE_DATE:'Data de criação',
      PUBLISH:'Publicar',
      UNPUBLISH:'Despublicar',
      PUBLISHED:'Publicado',
      UNPUBLISHED:'Despublicado',
      SUMMARY_NEWS:'Resumo de News',
      YES:'Sim',
      NO:'Não',
      CREATED:'Criado',
      APPLY:'Aplicar',
      PERSONAL_INFO_2:'Configure as informações pessoais',
      EMAIL_CONFIRM_1:'Repita o email',
      PROFILE_UPDATE:'Atualizar perfil',
      PASS_SETTINGS:'Configuração de senha',
      PASS_DESC:'Altere ou redefina sua senha',
      PASS_CONFIRM_1:'Repita a senha',
      USER_ADMIN_SUBTITLE:'Configurações administrativas. Informe a autoridade do usuário abaxio.',
      USER_ADMIN_LOCKED:'Usuário bloqueado por mais de 5 tentativas inválidas',
      LOCKED:'Bloqueado',
      UNLOCKED:'Desbloqueado',
      USER_TYPE:'Tipo de usuário',
      USER_COMPANY:'Empresa do usuário',
      USER_LG:'Usuário LG',
      USER_AGENCY:'Usuário de agência',
      PASS_CREATION:'Criação de senha',
      PASS_CREATION_1:'Crie uma senha inicial para o usuário - A senha deve conter: uma letra maiuscula, um número e um caracter especial.',
      PASS_CREATION_2:'Após 5 tentativas erradas, o usuário será bloqueado. Neste caso será necessario desbloquear o usuário novamente.',
      PASS_CONFIRM_2:'Senha novamente',
      UPDATE:'Atualizar',
      NEW_USER:'Adicionar novo usuário',
      BLOCKED:'Bloqueado',
      SUMMARY_VIDEOS:'Resumo de Video',
      AUTOCAD:'Autocad',
      VIDEO_LINKS:'Link do Video ',
      PRODUTOS_LG:'Produtos LG',

      MODAL_ALERT_TITLE:'Você quer excluir esses itens?',
      MODAL_ALERT_DEC:'Clique em OK para confirmar sua ação',
      
      NAV_CAMP:'Campanhas',
      NAV_INV:'Notas Fiscais',
      NAV_PROD:'Produtos',
      INVOICES:'Notas',
      MODAL_TITLE_CAMP:'Aprovação campanha', 
      MODAL_TITLE_DESC:'Aprove os cadastros de pontos', 
      MODAL_BTN_INV:'Visualizar nota',
      MODAL_FORM_CNPJ:'CNPJ', 
      MODAL_FORM_DATE:'Data de venda', 
      MODAL_FORM_INV:'Nota',   
      MODAL_FORM_VALUE:'Valor da nota', 
      MODAL_FORM_MODEL:'Modelo',
      MODAL_FORM_VALUE_PROD:'Valor do produto (Unit.)',
      MODAL_FORM_QTD:'Quantidade',
      MODAL_FORM_PROD:'Produto',     
      MODAL_SUBTITLE:'Produtos',
      REG_TITLE_APRO:'Aguardando aprovação',
      REG_TITLE_APRO_TAG:'Novos',
      REG_TITLE_APROVADOS:'Aprovados',
      TABLE_CAMP:'Campanha',
      CAMP_FORM_REG:'Regulamento',
      CAMP_FORM_START:'Início',
      CAMP_FORM_END:'Fim',
      CAMP_FORM_TITLE_PROD:'Produtos participantes',
      CAMP_FORM_LABEL_CAT:'Categoria',
      CAMP_FORM_LABEL_MOD:'Modelo',
      CAMP_FORM_LABEL_PONT:'Ponto do produto',
      CAMP_BTN_PROD:'Produto',
      CAMP_BTN_CAD:'Exibir cadastro',
      FORM_ALERT_SPAN:'Campo obrigatório',
      CAMP_TABELA_TITLE:'Campanha',
      CAMP_TABELA_TITLE_2:'Data Criação',
      PROD_LABEL_CAT:'Categoria',
      PROD_LABEL_MOD:'Modelo',
      PROD_LABEL_IMG:'Imagem',
      PROD_LABEL_DEC:'Descrição',
      PROD_TABLE_TITLE:'Produto',
      PROD_TABLE_TITLE_2:'Categoria',
      PROD_TABLE_TITLE_3:'Data Criação',

    }
}

const i18n = createI18n({
  locale: 'pt',
  fallbackLocale: 'en',
  messages,
});

createApp(App).use(router).use(store).use(VueApexCharts).use(i18n).directive('click-outside', {
    beforeMount(el, binding, vnode) {
      el.clickOutsideEvent = (event) => {
        if (!(el === event.target || el.contains(event.target))) {
            binding.value(event, el);
        }
      };
      document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
      document.body.removeEventListener("click", el.clickOutsideEvent);
    },
  }).mount('#app')