import ArchService from '../services/architect.service';

const user = JSON.parse(localStorage.getItem('user'));
const architects = {approved:{}, wait:{}}

export const arch = {
  namespaced: true,
  architects: architects,
  actions: {
    getArquitects({ commit }, args) {
      return ArchService.getArquitects(args[0], args[1]?.toString(), args[2]?.toString(), args[3]?.toString(), args[4]?.toString(), args[5]?.toString()).then(
        archs => {
          commit('archsSuccess', archs, args[0]);
          return Promise.resolve(archs);
        },
        error => {
          commit('archsFailure');
          return Promise.reject(error);
        }
      );
    },
    getArquitect({ commit },id) {
      return ArchService.getArquitect(id).then(
        (arch) => {
          return Promise.resolve(arch);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    approve({ commit },args) {
      return ArchService.approveArquitect(args[0], args[1]).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    reject({ commit }, args) {
      return ArchService.rejectArquitect(args[0], args[1]).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    deleteArquitect({ commit },id) {
      return ArchService.deleteArquitect(id).then(
        (resp) => {
          return Promise.resolve(resp);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },
    updatePart({ commit }, form) {
      return ArchService.updatePart(form[0], form[1]);
    },/*
    reset({ commit }, username) {
      return AuthService.reset(username).then(
        () => {
          commit('reset');
          return Promise.resolve("DONE");
        },
        error => {
          commit('resetFail');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },
    refreshTokenStart({ commit }) {
      AuthService.refreshToken().then(
        user => {
          commit('refreshTokenStart', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    }*/
  },
  mutations: {
    archsSuccess(architects, archs, status) {
      if(status == "APPROVED")
        architects.approved.push(archs.data.data);
      if(status == "ANALYSIS")
        architects.wait.push(archs.data.data);
    },
    archsFailure() {
      console.log("ERRO");
    }
    /*logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    refreshTokenStart(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    token(state) {
      state.status.token = true;
    },
    tokenFail(state) {
      state.status.token = false;
    },
    reset(state) {
      state.status.reset = true;
    },
    reset(state) {
      state.status.reset = false;
    },*/
  }
};
