import axios from "./api";

class ArchitectService {

  getInvoicesProducts(statusId, offset, limit, direction, order, search) {
    var params = '?';

    if(Array.isArray(statusId))
      statusId = statusId.join()

    if(statusId && statusId !== null && statusId !== '')
      params+='statusId='+statusId+'&'
    if(offset && offset !== null && offset !== '')
      params+='offset='+offset+'&'
    if(limit && limit !== null && limit !== '')
      params+='limit='+limit+'&'
    if(direction && direction !== null && direction !== '')
      params+='direction='+direction+'&'
    if(order && order !== null && order !== '')
      params+='order='+order+'&'
    if(search && search !== null && search !== '')
      params+='search='+search+'&'
    return axios.get('/invoices/invoice-products'+params);
  }

  getArquitect(id) {
    return axios.get('/participants/'+id);
  }

  deleteArquitect(id) {
    return axios.delete('/participants/'+id);
  }

  approve(id, reason) {
    return axios.post('/invoices/invoice-products/'+id+'/approve',{
      reason: reason
    });
  }

  reject(id, reason) {
    return axios.post('/invoices/invoice-products/'+id+'/reject', {
      reason: reason
    });
  }

  updatePart(id, body){
    return axios.put("participants/"+id, body);
  }

  getFile(code){
    return axios.get("/invoices/files/"+code, {responseType: "arraybuffer"});
  }
}

export default new ArchitectService();
