<template>
    <div class="modal__overlay" >
        <transition name="modal-animation" appear>
            <div v-click-outside="clickOutside" class="modal__container">
                <div class="modal--header">
                    <div class="modal--header--container">
                        <slot name="header--modal"></slot>
                    </div>
                    <button @click.prevent="close()" class="modal--close" title="Close">
                        <svg width="15" height="15" viewBox="0 0 14 14">
                            <path d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z"  fill-rule="evenodd" title="Close" alt="Close" />
                        </svg>
                    </button>
                </div>
                <section class="main__modal">
                <slot name="container--modal"> </slot>    
                <div class="clear"></div>          
                </section>              
            </div>
        </transition>
    </div>
</template>

<script>


export default {
    props: {
        // title: {
        //     type: String,
        //     required: true
        // },
       
    },
    mounted(){
    //   this.view = false;
    //   var self=this;
        // if (document.readyState === "complete" || document.readyState === "loaded") {
        // document is already ready to go

        // self.tags();
        // }else
        // document.addEventListener("DOMContentLoaded", function() {

        // self.tags();
        // });
  },
    methods: {
        close() {
            this.$emit('toggle-modal');
        },
        clickOutside(e){
            this.$emit('clickOut', e);
        }
    //     tags(){
    //          let tokenAutocomplete = new TokenAutocomplete({
    //             name: 'sample',
    //             selector: '#sample',
    //             noMatchesText: 'No matching results...',
    //             initialTokens: [
    //                 {value: 'Dashboard', text: 'Dashboard'},
    //                 {value: 'News', text: 'News'},
    //             ],
    //             initialSuggestions: [
    //                 {value: 'apple', text: 'Apple'},
    //                 {value: 'banana', text: 'Banana'},
    //                 {value: 'donut', text: 'Donut'},
    //                 {value: 'pizza', text: 'Pizza'},
    //                 {value: 'burger', text: 'Burger'},
    //                 {value: 'pancake', text: 'Pancake'}
    //             ]
    //         });
    //         tokenAutocomplete.debug(true);
			
	//   },
    }

}

</script>
