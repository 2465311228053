import dt from 'date-and-time';
import Modal from '@/components/Modal.vue';
import { Base64 } from 'js-base64';
export default {

    name: "Login",
    components: {
        Modal,
    },
    props: {
        search: String,
        propiedades: Object
    },
    data() {
        return {
            statusEnum: {
                ANALYSIS: 1,
                REJECTED: 2,
                APPROVED: 3,
                LIVELO_SYNCED: 4,
            },
            loading: {
                approved: {
                    show: false,
                    msg: '',
                    loading: ''
                },
                rejected: {
                    show: false,
                    msg: '',
                    loading: ''
                },
                toAprove: {
                    show: false,
                    msg: '',
                    loading: ''
                }
            },
            isEdit: false,
            invoicesToApprove: [],
            invoiceNotToApprove: [],
            invoice: {},
            view: false,
            page: {
                toAprove: 1,
                approve: 1,
                rejected: 1
            },
            sort: {
                analyse: {
                    field: 'createdAt',
                    order: 'desc',
                    search: ''
                },
                other: {
                    field: 'createdAt',
                    order: 'desc',
                    search: ''
                }
            },
            reasonFg: false,
            reason: "",
            totalRejected: 0,
            totalApproved: 0,
            totalToApproved: 0,
            action: '',
            isVisibility: false
        };
    },
    watch: {
        'sort.analyse.search': function (val) {
            this.page.toAprove = 1,
                this.loadtoAprove();
        },
        'sort.other.search': function (val) {
            this.page.approve = 1,
                this.loadapprove();
        }
    },
    computed: {
        loggedIn() {
            return this.$store.architects;
        }
    },
    mounted() {
        this.view = false;
    },
    created() {
        document.title = 'Aprovar notas | ' + document.title.substring(document.title.lastIndexOf('|') + 1)
        this.propiedades.descricao = 'Aprove ou reprove os cadastro de pontos'
        this.propiedades.helperTitle = 'The members can have 3 status.'
        this.propiedades.helperDesc = '1 - On Analysis: The initial status, where the member has limitaded access to the portal' +
            '<br/>2 - Approved' + '<br/>3 - Rejects<br/>To have full access the member have to be approved, in case of rejection  is necessary to fill the reason to.'
        var self = this;
        this.loadapprove()
        // this.loading.toAprove.show = true;
        // self.loading.toAprove.msg = "Loading"
        // this.loading.toAprove.loading = setInterval(() => {
        //     self.loading.toAprove.msg+="."
        //     if(self.loading.toAprove.msg.length>20)
        //         self.loading.toAprove.msg = 'Loading'
        // }, 500);
        this.loadtoAprove();
        this.$store.dispatch("auth/alterMenu", "Aprovação Pontos");
    },
    methods: {
        dataFormat(dat) {
            return dt.format(dt.parse(dat, 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'), 'DD-MM-YYYY HH:MM');
        },
        nextPage(e, type) {
            e.preventDefault();
            this.page[type] += 1;
            this['load' + type]();
        },
        previousPage(e, type) {
            e.preventDefault();
            this.page[type] -= 1;
            this['load' + type]();
        },
        loadapprove() {
            let list = [this.statusEnum.REJECTED, this.statusEnum.APPROVED, this.statusEnum.LIVELO_SYNCED]
            this.$store.dispatch("invoice/getInvoicesProducts", [list, (this.page.approve - 1) * 5, 5, this.sort.other.order, this.sort.other.field, this.sort.other.search]).then((resp) => {
                this.invoiceNotToApprove = resp.data.rows;
                this.totalApproved = resp.data.count;
            });
        },
        loadtoAprove() {
            this.$store.dispatch("invoice/getInvoicesProducts", [this.statusEnum.ANALYSIS, (this.page.toAprove - 1) * 5, 5, this.sort.analyse.order, this.sort.analyse.field, this.sort.analyse.search]).then((resp) => {
                this.invoicesToApprove = resp.data.rows;
                this.totalToApproved = resp.data.count;
            });
        },
        getInvoices() {
            this.loadapprove();
            this.loadtoAprove();
        },
        approve(id) {
            this.$store.dispatch("invoice/approve", [id, this.invoice.reason]).then((resp) => {
                this.reasonFg = false;
                this.action = 'APPROVED'
                this.toggleModal()
                this.invoice = resp.data.rows[0];
                if (!this.invoice.reason)
                    this.invoice.reason = '';
                this.propiedades.feedback.status = 'SUCCESS'
                this.propiedades.feedback.message = 'Produto da nota fiscal aprovado com sucesso!'
                this.getInvoices();
            }).catch(error => {
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = 'Falha na aprovação do produto da nota fiscal!'
            });
        },
        reject(id) {
            if (!this.invoice.reason.trim()) {
                this.reasonFg = true;
                return;
            }
            this.$store.dispatch("invoice/reject", [id, this.invoice.reason]).then((resp) => {
                this.reasonFg = false;
                this.action = 'REJECTED'
                this.toggleModal()
                this.invoice = resp.data.rows[0];
                if (!this.invoice.reason)
                    this.invoice.reason = '';
                this.propiedades.feedback.status = 'SUCCESS'
                this.propiedades.feedback.message = 'Produto da nota fiscal rejeitado!'
                this.getInvoices();
            }).catch(error => {
                this.propiedades.feedback.status = 'DANGER'
                this.propiedades.feedback.message = 'Falha na rejeição do produto da nota fiscal!'
            });
        },
        visual(obj, event, act) {
            this.invoice = obj;
            if (!this.invoice.reason)
                this.invoice.reason = '';
            //event.preventDefault();
            this.isEdit = false;
            this.action = act;
            this.isVisibility = true;

        },
        setSortWait(field) {
            this.sort.analyse.field = field;
            this.sort.analyse.order = this.sort.analyse.order === 'desc' ? 'asc' : 'desc';
            this.page.toAprove = 1
            this.loadtoAprove();
        },
        setSortOther(field) {
            this.sort.other.field = field;
            this.sort.other.order = this.sort.other.order === 'desc' ? 'asc' : 'desc';
            this.page.approve = 1
            this.loadapprove();
        },
        toggleModal(e, obj, action) {
            this.isVisibility = false;
        },
        openNewTab(){
            // const routeData = this.$router.resolve({name: 'iFrame', query: {code: this.invoice.invoice.file.externalCode}});
            let code = this.invoice.invoice.file.externalCode
            let token = Base64.encode(this.$store.state.auth.user.access_token)
            let url = process.env.VUE_APP_API_URL
            window.open(url+'invoices/files/'+code+'/tkn'+token, '_blank');
        }
    }
};