<template>
	<div class="main-login">
    <div class="container">

			<div class="side-form">
				<img class="logo-lg" src="@/assets/login/img/logo-lg.svg" alt="LG" title="LG">
				<h1>{{isResetPass?$t('PASS_RESET'):$t('WELCOME')}}</h1>
				<form action="" @submit="handleLogin">
					<div class="form-content grid-form-60">
						<label for="email">{{$t('EMAIL')}}</label>
						<input v-on:keyup="limpaErro()" type="email" name="email" v-model="user.username" class="mail"  placeholder=" " >
					</div>
					<div v-show="!isResetPass" class="form-content grid-form-40">
						<label for="senha">{{$t('PASS')}}</label>
						<input v-on:keyup="limpaErro()" :type="isPass?'text':'password'" v-model="user.password" name="senha" class="pass" placeholder=" ">
						<i :class="[this.isPass?'bi bi-eye':'bi bi-eye-slash']" @click="isPass = !isPass" id="togglePassword"></i>
					</div>
					<div v-show="!isResetPass && feedbackLabel.login.erro" class="form-content grid-form-100">
						<p class="send__token" :class="[!this.feedbackLabel.login.erro?'':'danger', 'send__token']">{{feedbackLabel.login.message}}</p>
					</div>
					<div v-show="!isResetPass" class="form-content grid-form-40">					
						<button v-show="!isTokenSent" type="button" :disabled="isDisabledToken" @click="sendMyToken()" class="btn-token">{{$t('SEND_TOKEN')}}</button>
						<button v-show="isTokenSent" class="btn-token" disabled>{{timeCount}}</button>
					</div>
					<div v-show="!isResetPass" class="form-content grid-form-60">
						<label for="Token">{{$t('TOKEN')}}</label>
						<input v-on:keyup="limpaErro()" type="text" name="token" class="token" v-model="user.token"  placeholder=" ">
					</div>
					<div v-show="!isResetPass && feedbackLabel.token.message !== ''" class="form-content grid-form-100">
						<p v-show="true" :class="[!this.feedbackLabel.token.erro?'':'danger', 'send__token']">{{feedbackLabel.token.message}}</p>
					</div>
					<div v-show="isResetPass && feedbackLabel.reset.message !== ''" class="form-content grid-form-100">
						<p v-show="true" :class="[!this.feedbackLabel.reset.erro?'':'danger', 'send__token']">{{feedbackLabel.reset.message}}</p>
					</div>

					<div class="form-content grid-form-100">	

					<span class="line"></span>
					</div>
					<div class="form-content grid-form-50">
						<button type="button" @click="isResetPass = false" v-show="isResetPass" class="gray">{{$t('BACK')}}</button>
						<a v-show="!isResetPass" @click="resetPass($event)" :disabled="isDisabledToken"  href="#" class="link-pass">{{$t('FORGOT_PASS')}}</a>
					</div>
					<div class="form-content grid-form-50">
						<button v-show="!isResetPass" :disabled="isDisabled">{{$t('LOGIN_1')}}</button>
						<button @click="sendResetPass()" type="button" v-show="isResetPass" :disabled="!user.username">{{$t('RESET')}}</button>
					</div>
				</form>

				<p class="txt-footer">{{new Date().getFullYear()+$t('LEGAL_LG_3')}}<strong>{{ version }}</strong><br/>
					{{$t('ENVIRONMENT')}}<b>{{env}}</b>
				</p>
				<!-- <div class="map--column">
                    <h6>{{$t('LANG')}}</h6>
                    <select @change="changeLang" v-model="$i18n.locale">
                        <option>en</option>
                        <option>pt</option>
                    </select>
                </div> -->
			</div>
			
			<div class="side-image">
				<img src="@/assets/login/img/intro.png" alt="Image CMS" title="CMS">
			</div>
			
		</div>
		</div>
</template>

<script>

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      env: process.env.NODE_ENV.toUpperCase(),
      version: process.env.VUE_APP_VERSION,
      message: "",
      isResetPass: false,
      isTokenSent: false,
      isPass: false,
      timeCount: "",
      user: {
        username: "",
        password: "",
        token: "",
      },
      feedbackLabel: {
        login: {
          erro: false,
          message: ''
        },
        token: {
          erro: false,
          message: ''
        },
        reset: {
          erro: false,
          message: ''
        },
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
	isDisabled: function(){
        return !this.user.token || !this.user.token.trim() || !this.user.username || !this.user.username.trim() || !this.user.password || !this.user.password.trim();
    },
	isDisabledToken: function(){
        return !this.user.username || !this.user.username.trim();
    }
  },
  created() {
		document.title= 'Login | ' + document.title.substring(document.title.lastIndexOf('|') + 1)
    if (this.loggedIn) {
      this.$router.push("/portal/dashboard");
    }
  },
  methods: {
	limpaErro(){
		this.feedbackLabel.reset.message ="";
		this.feedbackLabel.reset.erro = false;
		this.feedbackLabel.token.message ="";
		this.feedbackLabel.token.erro = false;
		this.feedbackLabel.login.message = "";
		this.feedbackLabel.login.erro = false;
	},
	limpaToken(){

	},
    handleLogin(e) {
		e.preventDefault();
      this.loading = true;

      this.$store.dispatch("auth/login", this.user).then(
        (resp) => {
			this.feedbackLabel.login.erro = false;
          	this.$router.push("/portal/dashboard");
			this.$i18n.locale = resp.language
        },
        (error) => {
			this.feedbackLabel.login.erro = true;
			this.feedbackLabel.login.message = error.response.data.message;
        }
      ).catch(error =>{
			this.feedbackLabel.login.erro = true;
			this.feedbackLabel.login.message = "Erro desconhecido ao efetuar o login.";
	  });
    },
	sendMyToken(){
		if(!this.user.username){
			this.feedbackLabel.login.erro = true;	
			this.feedbackLabel.login.message = "Por favor preencha o email!";
			return;
		}

		this.$store.dispatch("auth/token", this.user.username).then(
        () => {
			this.feedbackLabel.token.erro = false;
			this.feedbackLabel.token.message ="Token enviado para seu e-mail";
			this.isTokenSent = true;
			this.countDown();
        },
        (error) => {
			this.feedbackLabel.token.erro = true;
			this.feedbackLabel.token.message = error.response.data.message;
		}
      ).catch(error => {
		  	this.feedbackLabel.token.erro = true;
			this.feedbackLabel.token = "Erro ao enviar Token";
	  });
	},
	resetPass(e){
		e.preventDefault();
		this.isResetPass = true;
	},
	sendResetPass(){
		this.$store.dispatch("auth/reset", this.user.username).then(
        () => {
			this.feedbackLabel.reset.erro = false;
          	this.feedbackLabel.reset.message = "Password sent!"
        },
        (error) => {
			this.feedbackLabel.reset.erro = true;
          	this.feedbackLabel.reset.message = error.response.data.message;
        }
      ).catch(error => {
		  	this.feedbackLabel.reset.erro = true;
			this.feedbackLabel.reset.message = "Erro ao enviar Token";
	  });
	},

	countDown(){
		var countDownDate = new Date();
		countDownDate.setMinutes(countDownDate.getMinutes() + 2);
		countDownDate = countDownDate.getTime();
		var self = this;
		var x = setInterval(function() {
			var distance = countDownDate - new Date().getTime();

			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);

			self.timeCount = minutes+":"+(seconds>=10?seconds:"0"+seconds);
			if (distance < 0) {
				clearInterval(x);
				self.isTokenSent = false;
			}
		}, 500);
	},
	changeLang(){
		if(this.$store.state.auth.user)
			this.$store.dispatch('auth/changeLang', [this.$store.state.auth.user.id, this.$i18n.locale]);
	}
  },
};
</script>
<style scoped lang="sass" src="../assets/login/sass/app.sass">
</style>
