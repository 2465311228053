import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
var menu = "";
const initialState = user
  ? { status: { justLogged: false, loggedIn: true }, user, menu: menu }
  : { status: { justLogged: false, loggedIn: false }, user: null, menu: menu};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    token({ commit }, username) {
      return AuthService.token(username).then(
        () => {
          commit('token');
          return Promise.resolve("DONE");
        },
        error => {
          commit('tokenFail');
          return Promise.reject(error);
        }
      );
    },
    reset({ commit }, username) {
      return AuthService.reset(username).then(
        () => {
          commit('reset');
          return Promise.resolve("DONE");
        },
        error => {
          commit('resetFail');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit('logout');
      AuthService.logout();
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }, accessToken) {
      commit('refreshToken', accessToken);
    },
    refreshTokenStart({ commit }) {
      AuthService.refreshToken().then(
        user => {
          commit('refreshTokenStart', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        })
    },
    alterMenu({ commit }, mn){
      commit('menu', mn);
    },
    async changeLang({ commit }, arr) {
      await AuthService.changeLang(arr[0]?.toString(), 
        arr[1]?.toString())
      .then(resp => {
        commit('lang', resp?.data?.language);
      })
      .catch(e =>{console.log('EROR CHANGING LANG: '+e)});
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      state.justLogged = true;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    refreshTokenStart(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    token(state) {
      state.status.token = true;
    },
    tokenFail(state) {
      state.status.token = false;
    },
    reset(state) {
      state.status.reset = true;
    },
    reset(state) {
      state.status.reset = false;
    },
    menu(state, mn) {
      state.menu= mn;
    },
    lang(state, lang) {
      if(state.user)
        state.user.language = lang;
    },
  }
};
